var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-pcom" },
    [
      _c(
        "section",
        {
          staticClass: "section-text pt-0",
          staticStyle: {
            background:
              "url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff",
          },
        },
        [
          _c(
            "main",
            {
              staticClass:
                "container-text container-text-login-box-wrp mt-0 pt-3",
            },
            [
              _c("div", { staticClass: "white-inset login-box-wrp" }, [
                _c("div", { staticClass: "inset-body pt-5" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  !_vm.$isLive
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._m(1),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    [
                      _c("form-input", {
                        attrs: {
                          "help-text":
                            "We'll never share your email with anyone else.",
                          label: "Email",
                          type: "email",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
                        },
                        model: {
                          value: _vm.email,
                          callback: function ($$v) {
                            _vm.email = $$v
                          },
                          expression: "email",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-password", {
                        attrs: {
                          label: "Password",
                          "help-text":
                            "Strong passwords are longer than 8 characters and consist of multiple words.",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.password,
                          callback: function ($$v) {
                            _vm.password = $$v
                          },
                          expression: "password",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-input", {
                        attrs: {
                          label: "First Name",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.firstName,
                          callback: function ($$v) {
                            _vm.firstName = $$v
                          },
                          expression: "firstName",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-input", {
                        attrs: {
                          label: "Last Name",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.lastName,
                          callback: function ($$v) {
                            _vm.lastName = $$v
                          },
                          expression: "lastName",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-input", {
                        attrs: {
                          label: "Institution",
                          "form-id": _vm.zFormId,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.school,
                          callback: function ($$v) {
                            _vm.school = $$v
                          },
                          expression: "school",
                        },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group" }, [
                        _c(
                          "div",
                          { staticClass: "d-grid gap-2" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                attrs: { type: "submit" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.btnStartFreeTrial.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tStart my Free Trial\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "router-link",
                              {
                                staticClass:
                                  "btn btn-outline-primary btn-block",
                                attrs: {
                                  to: {
                                    name: "Login",
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\tI already have  an account\n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(2),
      _vm._v(" "),
      _c("price-grid-v2", {
        attrs: { "is-show-prices": false, "is-show-testimonial-video": false },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "testimonials-wrp d-none d-lg-block hero hero-dark text-center",
          staticStyle: {
            "background-image":
              "linear-gradient(rgba(42, 80, 112, 0.43), rgba(42, 80, 112, 0.43)), url('/static/images/blue-icons.png')",
            "background-position": "0px 0px, 50% 50%",
            "background-size": "auto, 200px",
            "background-color": "rgb(42, 80, 112)",
          },
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("h1", [_vm._v("Don't just take our word for it")]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-4" }, [
                _vm._v(
                  "\n\t\t\t\tCheck out some actual reviews from students like you.\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c(
                "transition",
                { attrs: { name: "route-fade", mode: "out-in" } },
                [
                  _vm.currentSlide === 1
                    ? _c("div", { key: "slide1", staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "quote-wrp" },
                          [
                            _c("testimonial-row", {
                              attrs: { "pull-index": 0 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 1 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 2, "is-last": true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.currentSlide === 2
                    ? _c("div", { key: "slide2", staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "quote-wrp" },
                          [
                            _c("testimonial-row", {
                              attrs: { "pull-index": 3 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 4 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 5, "is-last": true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.currentSlide === 3
                    ? _c("div", { key: "slide3", staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "quote-wrp" },
                          [
                            _c("testimonial-row", {
                              attrs: { "pull-index": 6 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 7 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 8, "is-last": true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.currentSlide === 4
                    ? _c("div", { key: "slide4", staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "quote-wrp" },
                          [
                            _c("testimonial-row", {
                              attrs: { "pull-index": 9 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 10 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 11, "is-last": true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.currentSlide === 5
                    ? _c("div", { key: "slide5", staticClass: "slide" }, [
                        _c(
                          "div",
                          { staticClass: "quote-wrp" },
                          [
                            _c("testimonial-row", {
                              attrs: { "pull-index": 12 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 13 },
                            }),
                            _vm._v(" "),
                            _c("testimonial-row", {
                              attrs: { "pull-index": 14, "is-last": true },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center" }, [
      _c("h1", [_vm._v("PERRLA")]),
      _vm._v(" "),
      _c("h2", [
        _vm._v("\n\t\t\t\t\t\t\tStart a 7 day Free Trial\n\t\t\t\t\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fw-bold p-2" }, [
      _vm._v(
        "\n\t\t\t\t\t\t\tThis is testing environment, to log in please visit: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.perrlacomplete.com/Public/#/free-trial-start",
          },
        },
        [_vm._v("https://www.perrlacomplete.com/Public/#/free-trial-start")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-text" }, [
      _c("main", { staticClass: "container-text" }, [
        _c("h1", { staticClass: "text-center" }, [
          _vm._v(
            "\n\t\t\t\tWrite in Word or on the web - it's your choice.\n\t\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Every PERRLA subscription includes "),
          _c("strong", [_vm._v("PERRLA for Word")]),
          _vm._v(" & "),
          _c("strong", [_vm._v("PERRLA Online")]),
          _vm._v(" so you can write your papers however you feel comfortable."),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "\n\t\t\t\tNot sure which version of PERRLA to use?\n\t\t\t\t"
          ),
          _c("a", { attrs: { href: "https://perrla.com/#/perrla-vs-word" } }, [
            _vm._v("Click here to learn which one is right for you."),
          ]),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("strong", [
            _c(
              "a",
              { attrs: { href: "https://perrla.com/#/perrla-for-word" } },
              [_vm._v("PERRLA for Word")]
            ),
          ]),
          _vm._v(
            " \n\t\t\t\tworks directly with Microsoft Word to handle every part of APA & MLA formatting for you. Available for Windows & Mac.\n\t\t\t"
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _c("strong", [
            _c("a", { attrs: { href: "https://perrla.com/#/perrla-online" } }, [
              _vm._v("PERRLA Online"),
            ]),
          ]),
          _vm._v(
            " \n\t\t\t\tlets you write your papers from anywhere with our web-based editor. Then, download them as Word documents to turn in.\n\t\t\t"
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }