<!--
- Generic Modal > Shared References Received 
-- https://docs.google.com/document/d/1SEYz5JUk6alwIDEUlPhFhvf4L-RubSE07eVOxGIy6AQ/edit?tab=t.0#heading=h.n5rm5138r62r
-->

<template>
	<generic-modal-wrap modal-id="SharedReferencesReceived" :is-close-button="false">
		<h2 class="nodal-title">
			You've got shared references!
		</h2>
		
		<div v-if="thisSharedReference" key="divSharedReferenceInfo">
			<p>
				<strong>It looks like {{thisSharedReference.firstName}} has shared {{ thisSharedReference.references.length }} of their references with you.</strong>
					If you add the references to your PERRLA account, we'll create a copy in your library so you to use them in your own papers.</p>
			
			<p v-if="thisSharedReference.message.length > 0">{{thisSharedReference.firstName}} sent a message along with the references:</p>
			
			<div class="message-wrp word-break" v-if="thisSharedReference.message.length > 0">
				<p>
					{{ thisSharedReference.message }}
				</p>
			</div>

			<h2 class="mb-3">Do you want to copy these reference to your Reference Library?</h2>

			<div class="row">
				<div class="col">
					<div class="d-grid">
						<button type="button" class="btn btn-outline-danger" @click.prevent="btnIgnore">
							Decline Shared References
						</button>
					</div>
				</div>
				<div class="col">
					<div class="d-grid">
						<button type="button" class="btn btn-success" @click.prevent="btnAccept">
							Add References to My Library
						</button>
					</div>
				</div>
			</div>
		</div>

	</generic-modal-wrap>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalSharedReferencesReceived',
	computed:{
		thisSharedReference(){
			if(!_isEmpty(this.$store.state.references.referenceShareRequests)){
				return this.$store.state.references.referenceShareRequests[0];
			}
			return null;
		},
	},
	methods:{
		btnAccept(){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			let numberOfRefernecesAdded = this.thisSharedReference.references.length;

			ApiS_MakeCall({
				params:{
					'shareRequestID': this.thisSharedReference.genesisReferenceShareRequestID,
				},
				method: 'PUT',
				url: 'v4/GenesisReference/Share/Accept',
			}).then(()=>{
				this.$store.commit('references/SHIFT_REFERENCE_SHARE_REQUEST');
			
				this.$nextTick(()=>{
					// no more shared ref requests then close this out
					if(this.$store.state.references.referenceShareRequests.length === 0){

						AS_SyncData({
							isStealthSync: true
						}).then(()=>{
						
							this.$store.commit('gridSorts/REFERENCE_LIST_ORDER_BY', 'createdDate');
							this.$store.commit('gridSorts/REFERENCE_LIST_SORTS_TOGGLE', {
								key: 'createdDate',
								setSortTo: -1,
							});

							this.$nextTick(()=>{
								this.emitter.emit('updateReferenceList');
								
								this.$nextTick(()=>{

									this.$store.commit('modals/CLOSE', 'SharedReferencesReceived');

									this.emitter.emit('globalToasterOpen',{
										textContent: 'Shared references added to your library',
									});

									let referenceList_tbodyReferencesWrp = document.getElementById('ReferenceList_tbodyReferencesWrp')
									if(referenceList_tbodyReferencesWrp){
										const allChildren = referenceList_tbodyReferencesWrp.children;
										const firstXChildren = Array.from(allChildren).slice(0, numberOfRefernecesAdded);

										firstXChildren.forEach((element) => {
											element.classList.add('tr-background-success'); // Example: Add a class to the selected elements
										});


										// console.log(allChildren);
									}

									this.$store.commit('loaders/REMOVE_ID', 'App');

								});//e:$nextTick
							});//e:$nextTick

						});//e:AS_SyncData
					} else {
						this.$store.commit('loaders/REMOVE_ID', 'App');
					}//e:if

				});//e:$nextTick	

			});//e:ApiS_MakeCall
			
		},//e:btnAccept

		btnIgnore(){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			ApiS_MakeCall({
				params:{
					'shareRequestID': this.thisSharedReference.genesisReferenceShareRequestID,
				},
				method: 'PUT',
				url: 'v4/GenesisReference/Share/Decline',
			}).then(()=>{
				this.$store.commit('references/SHIFT_REFERENCE_SHARE_REQUEST');

				this.$nextTick(()=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');

					// no more shared ref requests then close this out
					if(this.$store.state.references.referenceShareRequests.length === 0){
						this.$store.commit('modals/CLOSE', 'SharedReferencesReceived');

						this.emitter.emit('globalToasterOpen',{
							textContent: 'Shared references ignored',
						});
					}

				});//e:$nextTick				
				

			});//e:ApiS_MakeCall
			
		},//e:btnIgnore
	},
	components:{
		GenericModalWrap
	},
}
</script>

<style lang="css" scoped>
h2 {
	font-size: 1.3rem;
	font-weight: 600;
}
.message-wrp{
	margin-left: 16px;
	font-weight: 300;
	font-style: italic;
}
</style>