<!--
- Settings Form Label and Input
-- Easier to start over without all the name maker bloat
-->

<template>
	<div class="form-input-v2 row mb-3">
		<div class="col-label">
			<label
				class="form-label form-label-v2"
				:for="fullId"
				v-html="calcLabelOutput" />
		</div>

		<div :class="{'col-value-full-width': isFullWidth, 'col-value': !isFullWidth }">
			<input
				autocomplete="off"
				:class="['form-control', {'is-invalid': isInvalidShown}]"	
				:disabled="isDisabled"
				:id="fullId"
				:placeholder="placeholder"
				:role="role"
				:type="localType"
				:value="value"
				@blur="blurInput($event.target.value)"
				@input="typeInput($event.target.value)" />
				
			<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
				{{ invalidErrorMessage }}
			</div>
		</div><!--col-->

	</div>
	
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import mixinId from '@/mixins/id';

export default {
	name: 'FormInputV2',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isDisabled: {
			default: false,
			type: Boolean,
		},
		isFullWidth:{
			default: false,
			type: Boolean,
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		role: {
			type: String,
		},
		type:{
			default: 'text',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			localType: null,
		}
	},
	computed:{
		calcLabelOutput(){
			if(
				this.validateEnum === config.enums.Validation.EMAIL_REQUIRED || 
				this.validateEnum === config.enums.Validation.NUMERIC_REQUIRED ||
				this.validateEnum === config.enums.Validation.REQUIRED
			){
				return this.label + '*';
			}
			// if(this.thisValidation === )
			return this.label;
		},
		fullId(){
			// generate one based on mixin if a specitic suffix isn't defined
			return this.safeId;
		},
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},

		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.fullId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		blurInput(value){
			AS_ValidateForm({
				singleFieldId: this.fullId
			}).then(()=>{
				this.$emit('blur-input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:blurInput

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.$emit('input', value);
		},//e:typeInput
	},
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.fullId,
				validateEnum: this.validateEnum,
			})
		}
		this.localType = this.type;
	},
}
</script>
