var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "layout-pcom" }, [
    _c("section", { staticClass: "section-text" }, [
      _c("main", { staticClass: "container-text mt-2" }, [
        _c("div", { staticClass: "white-inset w-75 mx-auto" }, [
          _c("div", { staticClass: "inset-body" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.hasAccount && _vm.isInvitationValid
              ? _c("div", { key: "divHasAccount" }, [
                  _c("h4", [
                    _vm._v("Welcome back, " + _vm._s(_vm.firstName) + "!"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.organizationName) +
                        " would like support your existing PERRLA subscription (they want to pay for it)"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [_vm._v("Log in below to connect your account.")]),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "form",
                    [
                      _c("form-input", {
                        attrs: {
                          label: "Account Email",
                          type: "email",
                          "form-id": _vm.zFormIdInvite,
                          "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
                        },
                        model: {
                          value: _vm.formEmail,
                          callback: function ($$v) {
                            _vm.formEmail = $$v
                          },
                          expression: "formEmail",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-input", {
                        attrs: {
                          label: "Password",
                          type: "password",
                          "form-id": _vm.zFormIdInvite,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.formPassword,
                          callback: function ($$v) {
                            _vm.formPassword = $$v
                          },
                          expression: "formPassword",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "p",
                        { staticClass: "link-forgot-password" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "hover-underline",
                              attrs: {
                                to: {
                                  name: "ForgotPassword",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tForgot password?\n\t\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-grid gap-2 mt-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnLogin.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tLogin\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnSetHasAccount(false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tCreate a new PERRLA Account\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : !_vm.hasAccount && _vm.isInvitationValid
              ? _c("div", { key: "divNewAccount" }, [
                  _c("h4", [_vm._v("Welcome, " + _vm._s(_vm.firstName) + "!")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.organizationName) +
                        " has provided your basic account information. You can edit this after you create your account."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Once you set your password below, you will use this email and your password to access PERRLA."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "ms-4" }, [
                    _c("strong", [_vm._v("Name:")]),
                    _vm._v(
                      " " + _vm._s(_vm.firstName) + " " + _vm._s(_vm.lastName)
                    ),
                    _c("br"),
                    _vm._v(" "),
                    _c("strong", [_vm._v("Account Email:")]),
                    _vm._v(" " + _vm._s(_vm.email) + "\n\t\t\t\t\t\t"),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Create a password for your account to get started."
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.isPasswordError
                    ? _c("div", { staticClass: "alert alert-danger" }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tPlease enter matching passwords.\n\t\t\t\t\t\t"
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "form",
                    { staticClass: "mt-2" },
                    [
                      _c("form-input", {
                        attrs: {
                          label: "Password",
                          type: "password",
                          "form-id": _vm.zFormIdConfirmPassword,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.formPassword,
                          callback: function ($$v) {
                            _vm.formPassword = $$v
                          },
                          expression: "formPassword",
                        },
                      }),
                      _vm._v(" "),
                      _c("form-input", {
                        attrs: {
                          label: "Confirm Password",
                          type: "password",
                          "form-id": _vm.zFormIdConfirmPassword,
                          "validate-enum": _vm.$enums.Validation.REQUIRED,
                        },
                        model: {
                          value: _vm.formPasswordConfirm,
                          callback: function ($$v) {
                            _vm.formPasswordConfirm = $$v
                          },
                          expression: "formPasswordConfirm",
                        },
                      }),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-grid gap-2 mt-3" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            attrs: { type: "submit" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnCreateAccount.apply(
                                  null,
                                  arguments
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tCreate My Account\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-outline-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.btnSetHasAccount(true)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t\tI already have a PERRLA Account\n\t\t\t\t\t\t\t\t"
                            ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  ),
                ])
              : !_vm.isInvitationValid
              ? _c("div", { key: "divInvalidInvite" }, [
                  _c("h4", [
                    _vm._v(
                      "Oops! It looks like this invitation is no longer available."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If you think this is an error, please reach out to the school or organization that invited you to use PERRLA."
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "If not, you can also reach out to our Customer Support Team for assistance."
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._m(2),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "section-text" }, [
      _c("main", { staticClass: "container-text mt-2" }, [
        _vm.isInvitationValid
          ? _c("div", { key: "divQuestionsValid" }, [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v(
                  "\n\t\t\t\t\tAnswers to a few common questions\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("Why is my name and email already provided?")]),
              _vm._v(" "),
              _c("p", { staticClass: "small mb-5" }, [
                _vm._v(
                  "\n\t\t\t\t\tWhen an organization invites you to use PERRLA, they have provided the initial information we need to set up your account. After you create your PERRLA account, you can edit your name in your PERRLA Settings. You can also add a secondary email address as well.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("How did I get this?")]),
              _vm._v(" "),
              _c("p", { staticClass: "small mb-5" }, [
                _vm._v(
                  "\n\t\t\t\t\tYou received this invitation because your school or organization is providing PERRLA for you to help you write your upcoming papers.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("What do I need to do now?")]),
              _vm._v(" "),
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  "\n\t\t\t\t\tAll you need to do is enter a password for your account in the form above.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "small mb-5" }, [
                _vm._v(
                  "\n\t\t\t\t\tOnce you set the password for your account, we'll provide instructions for how to get started with either our Microsoft Word Add-in or our web-based Editor.\n\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("h4", [_vm._v("What if I've used PERRLA before?")]),
              _vm._v(" "),
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  '\n\t\t\t\t\tNot a problem! Instead of setting a new password, select the "I already have a PERRLA Account" button above. Then you can log into your existing PERRLA Account.\n\t\t\t\t'
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "small mb-5" }, [
                _vm._v(
                  "\n\t\t\t\t\tBy using your existing account, you can keep all of your existing references AND your school or organization covers the cost of continuing to use PERRLA. It's really a win-win for you!\n\t\t\t\t"
                ),
              ]),
            ])
          : _c("div", { key: "divQuestionsInvalid" }, [
              _c("h4", [_vm._v("Why doesn't my invitation work?")]),
              _vm._v(" "),
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tThere are a few reasons a PERRLA invitation may not work.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "small" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tThe invitation link may have already been used by someone else. Each person receives their own unique, one-time use link to create a PERRLA Account. Everyone needs their own invitation that can be only used once.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "small mb-5" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tIf this is the first time you're using this link for your invitation, you may need to reach out to your school for clarification. Each school or organization handles their PERRLA invitations directly.\n\t\t\t\t\t"
                ),
              ]),
            ]),
        _vm._v(" "),
        _c("h4", [_vm._v("Have more questions?")]),
        _vm._v(" "),
        _c("p", { staticClass: "small" }, [
          _vm._v("\n\t\t\t\tWe have more answers!\n\t\t\t"),
        ]),
        _vm._v(" "),
        _vm._m(3),
      ]),
    ]),
    _vm._v(" "),
    _vm.isInvitationValid
      ? _c("section", { staticClass: "section-text pt-0" }, [
          _c(
            "main",
            { staticClass: "container-text" },
            [
              _c("h2", { staticClass: "text-center" }, [
                _vm._v("\n\t\t\t\tSome more information about PERRLA\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("price-grid-v2", {
                attrs: {
                  "is-show-prices": false,
                  "is-show-testimonial-video": false,
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 text-center" }, [
        _c("img", {
          staticClass: "leego-img",
          attrs: {
            src: "///perrla.s3.amazonaws.com/images/logo-p-small.png",
            alt: "PERRLA, LLC",
          },
        }),
        _vm._v(" "),
        _c("h1", [_vm._v("PERRLA")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "link-forgot-password" }, [
      _c(
        "a",
        {
          staticClass: "hover-underline",
          attrs: {
            href: "mailto:support@perrla.com?subject=My PERRLA invitation doesn't match",
          },
        },
        [
          _vm._v(
            "\n\t\t\t\t\t\t\t\t\tIf this isn't you, click here to contact our Customer Support Team\n\t\t\t\t\t\t\t\t"
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-grid mt-3" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-outline-primary",
          attrs: { href: "mailto:support@perrla.com" },
        },
        [_vm._v("\n\t\t\t\t\t\t\t\tContact Customer Support\n\t\t\t\t\t\t\t")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "small" }, [
      _vm._v("\n\t\t\t\tCheck out our "),
      _c(
        "a",
        {
          attrs: {
            href: "https://perrla.zendesk.com/hc/en-us",
            target: "_blank",
            rel: "noopener noreferrer",
          },
        },
        [_vm._v("Help Center")]
      ),
      _vm._v(
        " for more articles and videos on how to use PERRLA. Or, reach out to our "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "mailto:support@perrla.com",
            id: "https-www.perrlacomplete.com-ContactSupport",
          },
        },
        [_vm._v("Customer Support team")]
      ),
      _vm._v(
        " directly. They are available 7 days a week until 10pm Central.\n\t\t\t"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }