<!--
- Shared Reference Invitations
--
-->

<template>
	<div>
		<div class="d-flex flex-row mb-2">
			<h1 class="float-start me-3 mb-0">
				References Shared with You
			</h1>
		</div><!--flex-row-->

			
		<div class="row">
			<div class="col-12 min-height overflow-y-auto position-relative" ref="divReferenceListWrp">
				<table v-if="arrayOfInvitations.length > 0" class="table table-borderless entity-grid">
					<thead>
						<tr>
							<th>Shared References</th>
														
							<th class="table-spacer" />

							<th>Message</th>

							<th class="table-spacer" />

							<th width="100px">Status</th>

						</tr>
					</thead>
					<tbody>
						<invitation-row
							v-for="(invitationData, index) in arrayOfInvitations"
							:key="index"
							:invitation-data="invitationData" />
					</tbody>
				</table>
				<div v-else class="text-muted text-center mt-5">
					<p>No invitations found.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SetLayout from '@/services/app/setLayout';
import AS_SyncData from '@/services/app/syncData';
import InvitationRow from './ReferencesSharedWithYou/Row';

export default {
	name: 'ReferencesSharedWithYou',
	data() {
		return {
			arrayOfInvitations: [],
		}
	},
	methods: {
		calcGridData(){
			ApiS_MakeCall({
				method: 'GET',
				responseType: 'json',
				url: 'v4/GenesisReference/Share',
			}).then((responseData)=>{
				
				this.arrayOfInvitations = responseData.reverse();

				this.$store.commit('loaders/REMOVE_ID', 'App');
			});//e:ApiS_MakeCall
		},
	},
	created(){
		this.emitter.on('updateInvitationList', ()=>{
			this.calcGridData();
			
		});
	},
	mounted() {
		AS_SyncData().then(()=>{
			AS_SetLayout().then(()=>{
				this.calcGridData();
			});//e:AS_SetLayout
		});//e:AS_SyncData
	},
	components: {
		InvitationRow, 
	},
	destroyed(){
		this.emitter.off('updateInvitationList');
	},
}
</script>
