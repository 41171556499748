<!--
- Start Free Trial
-
-->

<template>
	<div class="layout-pcom">

		<section class="section-text pt-0" style="background: url('https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/pencil-bg.png') repeat #fff;">
			<main class="container-text container-text-login-box-wrp mt-0 pt-3">

				<div class="white-inset login-box-wrp">
					<div class="inset-body pt-5">
						<div class="text-center">
							<h1>PERRLA</h1>
							<h2>
								Start a 7 day Free Trial
							</h2>
						</div>

						<div class="alert alert-danger" v-if="!$isLive">
							<p class="fw-bold p-2">
								This is testing environment, to log in please visit: <a href="https://www.perrlacomplete.com/Public/#/free-trial-start">https://www.perrlacomplete.com/Public/#/free-trial-start</a>
							</p>
						</div>

						<form>
							<form-input
								help-text="We'll never share your email with anyone else."
								label="Email"
								type="email"
								v-model="email"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.EMAIL_REQUIRED" />
							
							<form-password
								label="Password"
								help-text="Strong passwords are longer than 8 characters and consist of multiple words."
								v-model="password"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.REQUIRED" />

							<form-input
								label="First Name"
								v-model="firstName"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.REQUIRED" />

							<form-input
								label="Last Name"
								v-model="lastName"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.REQUIRED" />
							
							<form-input
								label="Institution"
								v-model="school"
								:form-id="zFormId"
								:validate-enum="$enums.Validation.REQUIRED" />

							<div class="form-group">
								<div class="d-grid gap-2">
									<button type="submit" class="btn btn-success" @click.prevent="btnStartFreeTrial">
										Start my Free Trial
									</button>

									<router-link
										:to="{
											name: 'Login'
										}"
										class="btn btn-outline-primary btn-block">
										I already have  an account
									</router-link>
								</div>
							</div>
						</form>

					</div><!--inset-body-->
				</div><!--white-inset-->
			</main>
		</section>

		<section class="section-text">
			<main class="container-text">
				<h1 class="text-center">
					Write in Word or on the web - it's your choice.
				</h1>

				<p>Every PERRLA subscription includes <strong>PERRLA&nbsp;for Word</strong>&nbsp;&amp;&nbsp;<strong>PERRLA&nbsp;Online</strong>&nbsp;so you can write your papers however you feel comfortable.</p>

				<p>
					Not sure which version of PERRLA to use?
					<a href="https://perrla.com/#/perrla-vs-word">Click here to learn which one is right for you.</a>
				</p>

				<p>
					<strong>
						<a href="https://perrla.com/#/perrla-for-word">PERRLA for Word</a>
					</strong> 
					works directly with Microsoft Word to handle every part of APA &amp; MLA formatting for you. Available for Windows &amp; Mac.
				</p>
				<p>
					<strong><a href="https://perrla.com/#/perrla-online">PERRLA Online</a></strong> 
					lets you write your papers from anywhere with our web-based editor. Then, download them as Word documents to turn in.
				</p>
			</main>
		</section>

		<!-- <price-grid v-if="$isLive"/> -->
		<!-- <price-grid-v2 v-else :is-show-prices="false" :is-show-testimonial-video="false" /> -->
		<price-grid-v2 :is-show-prices="false" :is-show-testimonial-video="false" />

		<!-- Testimonial Slideshow -->
		<div class="testimonials-wrp d-none d-lg-block hero hero-dark text-center" style="background-image: linear-gradient(rgba(42, 80, 112, 0.43), rgba(42, 80, 112, 0.43)), url('/static/images/blue-icons.png'); background-position: 0px 0px, 50% 50%; background-size: auto, 200px; background-color: rgb(42, 80, 112);">
			<div class="container">
				<h1>Don't just take our word for it</h1>
				<p class="mb-4">
					Check out some actual reviews from students like you.
				</p>

				<transition name="route-fade" mode="out-in">
					<div class="slide" v-if="currentSlide === 1" key="slide1">
						<div class="quote-wrp">
							<testimonial-row :pull-index="0" />
							<testimonial-row :pull-index="1" />
							<testimonial-row :pull-index="2" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 2" key="slide2">
						<div class="quote-wrp">
							<testimonial-row :pull-index="3" />
							<testimonial-row :pull-index="4" />
							<testimonial-row :pull-index="5" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 3" key="slide3">
						<div class="quote-wrp">
							<testimonial-row :pull-index="6" />
							<testimonial-row :pull-index="7" />
							<testimonial-row :pull-index="8" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 4" key="slide4">
						<div class="quote-wrp">
							<testimonial-row :pull-index="9" />
							<testimonial-row :pull-index="10" />
							<testimonial-row :pull-index="11" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

					<div class="slide" v-else-if="currentSlide === 5" key="slide5">
						<div class="quote-wrp">
							<testimonial-row :pull-index="12" />
							<testimonial-row :pull-index="13" />
							<testimonial-row :pull-index="14" :is-last="true" />
						</div><!--quote-wrp-->
					</div><!--slide-->

				</transition>

			</div><!--container-->
		</div>
	</div>
</template>

<script>
import _has from 'lodash/has';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_ValidateForm from '@/services/app/validateForm';
import config from '@/config';
import FormInput from '@/components/form/Input';
import FormPassword from '@/components/form/Password';
import PriceGrid from '@/components/PriceGrid';
import PriceGridV2 from '@/components/PriceGridV2';
import TestimonialRow from '@/components/TestimonialRow';

export default {
	name: 'FreeTrialStart',
	data() {
		return {
			currentSlide: 1,
			email: '',
			firstName: '',
			isLoginError: false,
			lastName: '',
			maxSlides: 5,
			password: '',
			school: '',
			stoSlide: null,	// timer
			zFormId: 'formStartFreeTrial',
		}
	},
	methods: {
		btnStartFreeTrial(){
			this.isLoginError = false;

			AS_ValidateForm({
				formId: this.zFormId
			}).then(()=>{
				this.$store.commit('loaders/ADD_ID', 'App');

				this.$store.commit('freeTrial/SET_EMAIL', this.email);
				this.$store.commit('freeTrial/SET_FIRST_NAME', this.firstName);
				this.$store.commit('freeTrial/SET_LAST_NAME', this.lastName);
				this.$store.commit('freeTrial/SET_PASSWORD', this.password);
				this.$store.commit('freeTrial/SET_SCHOOL', this.school);
			
				let sendBody = {
					email: this.$store.state.freeTrial.email,
					firstName: this.$store.state.freeTrial.firstName,
					lastName: this.$store.state.freeTrial.lastName,
					password: this.$store.state.freeTrial.password,
					platformID: config.platformId, 
					school: this.$store.state.freeTrial.school,
				};

				// console.log('sendBody')
				// console.log(sendBody)

				ApiS_MakeCall({
					body: sendBody,
					ignoreAuth: true,
					method: 'POST',
					responseType: 'text',
					url: 'v4/FreeTrial',
				}).then((responseData)=>{
					this.$store.commit('loaders/REMOVE_ID', 'App');

					this.$store.commit('forms/MARK_FORM_CLEAN_BY_ID', {
						formId: this.zFormId
					});

					this.$router.push({
						name: 'FreeTrialVerify',
						params: {
							verifyId: responseData
						}
					}).catch(()=>{});
					
				}).catch((error) =>{
					this.$store.commit('loaders/REMOVE_ID', 'App');

					if(
						error &&
						_has(error, 'code') &&
						_has(error, 'message') &&
						error.code === 'PENDING_ORG_INVITE'
					){
						this.$router.push({
							name: 'OrganizationInvite',
							params: {
								encryptedUserId: error.message
							}
						}).catch(()=>{});
					}
				});//e:ApiS_MakeCall

			}).catch((error)=>{
				this.$store.commit('loaders/REMOVE_ID', 'App');
				
			});//e:AS_ValidateForm

		},//e:btnStartFreeTrial

		slideNext(){
			if(this.currentSlide === this.maxSlides){
				this.currentSlide = 1;
			} else {
				this.currentSlide++;
			}
		},//e:slideNext

		slidePrev(){
			if(this.currentSlide === 1){
				this.currentSlide = this.maxSlides;
			} else {
				this.currentSlide--;
			}
		},//e:slidePrev

	},
	mounted() {
		// zero out your api token to simulate logged out
		// this.$store.commit('api/SET_TOKEN', '');
		
		// console.log(this.$store.state.api.token);

		this.$store.commit('loaders/REMOVE_ID', 'App');
		
		if(this.$store.state.api.token === ''){
			// blank token - user isn't logged in yet

			this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.PCOM);
		
			// force remove any theme classes - this view will not have a customer theme
			document.documentElement.setAttribute('data-bs-theme', 'light')
			document.body.classList.remove('theme-serrena');

			this.$store.commit('freeTrial/SET_EMAIL', '');
			this.$store.commit('freeTrial/SET_FIRST_NAME', '');
			this.$store.commit('freeTrial/SET_LAST_NAME', '');
			this.$store.commit('freeTrial/SET_PASSWORD', '');
			this.$store.commit('freeTrial/SET_SCHOOL', '');

			this.$store.commit('forms/ADD_FORM_TO_LIST',{
				formId: this.zFormId
			});

			// startup testimonial slideshow
			this.stoSlide = setInterval(()=>{
				this.slideNext();
			}, 5000);

		} else {
			// console.log('Already had a token');

			this.$router.push({
				name: 'Dashboard'
			}).catch(()=>{});
		}
	},
	components: {
		FormInput,
		FormPassword,
		PriceGrid,
		PriceGridV2,
		TestimonialRow
	}
}
</script>

<style lang="scss" scoped>
	@import "../assets/styles/vars_perrla";

	.hero {
		padding-top: 115px;
		padding-bottom: 115px;

		margin-left: -$grid-half-gutter;
		margin-right: -$grid-half-gutter;

		p {
			font-size: 1.2rem;
			font-weight: 400;
			line-height: 2.2rem;
			margin: 0 auto 8px auto;
				width: 75%;
		}

		ul{
			margin: 24px auto 0 auto;
			width: 50%;

			li {
				font-size: 19.2px;
				font-weight: 400;
				line-height: 36px;
				margin-bottom: 16px;
				text-align: left;
			}	
		}


		// Content Text Color
		h1, p {
			color:#fff;	
		}
		p {
			max-width:900px;
		}
		a.hero-link {
			color:#fff;
		}
		.btn {
			text-shadow: none;
		}
	
		

		// Content Text Shadow
		h1,p,a.hero-link {
			text-shadow: 0 0 0.5rem $body-color;
		}

	}



</style>