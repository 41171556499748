<!--
- Pricing Grid Ve
-- currently only on the test server while buildilng new pricing splits
-- https://perrla.webflow.io/pricing
-->

<template>
	<div class="mx-auto pricing-grid-wrp" style="max-width: 960px;">
        <section>
            
            <!-- Large View and Above -->
            <div class="container d-none d-lg-block mt-5" v-if="isShowPrices">
				<div class="row">
                    <div class="col-3">
                        <div class="text-center col-free-trial col-price-block">
                            <div class="tier-topper">
                                <h2>Free Trial</h2>
                            </div>
                            <div class="price-wrp">
                                <span class="dollar-sign">$</span>0
                            </div>
                            <div class="savings-wrp">
                                <h3>&nbsp;</h3>
                                <p>&nbsp;</p>
                            </div>
                            <div class="details-wrp">
                                <p>
                                    No restrictions!<br />
                                    No payment required!<br />
                                    7 Days to try PERRLA completely free.<br />
                                </p>
                            </div>
                            <div class="button-wrp" v-if="isShowButtons">
                                <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-outline-primary">
                                    TRY IT TODAY
                                </a>
                            </div>
                        </div><!--col-price-block-->
                    </div><!--col-3-->
                    
                    <div class="col-3">
                        <div class="text-center col-six-months col-price-block">
                            <div class="tier-topper">
                                <h2>6 months</h2>
                            </div>
                            <div class="price-wrp">
                                <span class="dollar-sign">$</span>49<span class="cents">.95</span>
                            </div>
                            <div class="savings-wrp">
                                <h3>&nbsp;</h3>
                                <p>&nbsp;</p>
                            </div>
                            <div class="details-wrp">
                                <p>
                                    Access to all our best products for students in shorter, semester long programs.
                                </p>
                            </div>
                            <div class="button-wrp" v-if="isShowButtons">
                                <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                    GET STARTED
                                </a>
                            </div>
                        </div><!--col-price-block-->
                    </div><!--col-3-->

                    <div class="col-3">
                        <div class="text-center col-one-year col-price-block">
                            <div class="tier-topper">
                                <h2>1 year</h2>
                            </div><!--tier-topper-->
                            <div class="price-wrp">
                                <span class="dollar-sign">$</span>79<span class="cents">.95</span>
                            </div>
                            <div class="savings-wrp">
                                <h3>Save 20%</h3>
                                <p>vs. the 6-month plan</p>
                            </div>
                            <div class="details-wrp">
                                <p>
                                    Our <i>most popular</i> choice for all students.<br/>
                                    Access to all our best products for the entire year!
                                </p>
                            </div>
                            <div class="button-wrp" v-if="isShowButtons">
                                <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                    GET STARTED
                                </a>
                            </div>
                            
                        </div><!--col-price-block-->
                       
                    </div><!--col-3-->

                    <div class="col-3">
                        <div class="text-center col-two-years col-price-block">
                            <div class="tier-topper">
                                <h2>2 years</h2>
                            </div><!--tier-topper-->
                            <div class="price-wrp">
                                <span class="dollar-sign">$</span>129<span class="cents">.95</span>
                            </div>
                            <div class="savings-wrp">
                                <h3>Save 35%</h3>
                                <p>vs. the 6-month plan</p>
                            </div>
                            <div class="details-wrp">
                                <p>
                                    This is a great value for students in longer programs - tackling the next degree in their field.
                                </p>
                            </div>
                            <div class="button-wrp" v-if="isShowButtons">
                                <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                    GET STARTED
                                </a>
                            </div>
                        </div><!--col-4-->
                    </div><!--col-price-block-->
                </div><!--row-->
			</div><!-- container -->

            <!-- Medium And Below Views -->
            <div class="d-block d-lg-none mx-auto" style="max-width: 728px;" v-if="isShowPrices">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center col-free-trial col-price-block">
                            <div class="tier-topper">
                                <h2>Free Trial</h2>
                            </div>

                            <div class="row">
                                <div class="col-sm-6 col-12">
                                    <div class="price-wrp pt-4">
                                        <span class="dollar-sign">$</span>0
                                    </div>
                                </div><!--col-6-->

                                <div class="col-sm-6 col-12 pt-3">
                                    <div class="details-wrp pe-4">
                                        <p>
                                            No restrictions!<br />
                                            No payment required!<br />
                                            7 Days to try PERRLA completely free.<br />
                                        </p>
                                    </div>
                                    
                                    <div class="button-wrp pe-0 pe-sm-4 d-none d-sm-grid" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-outline-primary">
                                            TRY IT TODAY
                                        </a>
                                    </div>
                                    
                                    <div class="button-wrp px-5 d-grid d-sm-none" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-outline-primary">
                                            TRY IT TODAY
                                        </a>
                                    </div>

                                </div><!--col-6-->
                            </div><!--row-->
                        </div><!--col-price-block-->
                    </div><!--g-col-12-->

                    <div class="col-12">
                        <div class="text-center col-six-months col-price-block">
                            <div class="tier-topper">
                                <h2>6 months</h2>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 col-12">
                                    <div class="price-wrp pt-4">
                                        <span class="dollar-sign">$</span>49<span class="cents">.95</span>
                                    </div>
                                </div><!--col-6-->

                                <div class="col-sm-6 col-12 pt-3">
                                    <div class="details-wrp pe-4">
                                        <p>
                                            Access to all our best products for students in shorter, semester long programs.
                                        </p>
                                    </div>

                                    <div class="button-wrp pe-0 pe-sm-4 d-none d-sm-grid" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>
                                    
                                    <div class="button-wrp px-5 d-grid d-sm-none" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>

                                </div><!--col-6-->
                            </div><!--row-->
                        </div><!--col-price-block-->
                    </div><!--col-12-->

                    <div class="col-12">
                        <div class="text-center col-one-year col-price-block">
                            <div class="tier-topper">
                                <h2>12 months</h2>
                            </div>

                            <div class="row">
                                <div class="col-sm-6 col-12">
                                    <div class="price-wrp pt-4">
                                        <span class="dollar-sign">$</span>79<span class="cents">.95</span>
                                    </div>
                                    <div class="savings-wrp">
                                        <h3>Save 20%</h3>
                                        <p>vs. the 6-month plan</p>
                                    </div>
                                </div><!--col-6-->

                                <div class="col-sm-6 col-12 pt-3">
                                    <div class="details-wrp pe-4">
                                        <p>
                                            Our <i>most popular</i> choice for all students.<br/>
                                            Access to all our best products for the entire year!
                                        </p>
                                    </div>
                                    
                                    <div class="button-wrp pe-0 pe-sm-4 d-none d-sm-grid" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>
                                    
                                    <div class="button-wrp px-5 d-grid d-sm-none" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>
                                </div><!--col-6-->
                            </div><!--row-->
                        </div><!--col-price-block-->
                    </div><!--g-col-12-->

                    <div class="g-col-12">
                        <div class="text-center col-two-years col-price-block">

                            <div class="tier-topper">
                                <h2>2 years</h2>
                            </div>

                            <div class="row">
                                <div class="col-sm-6 col-12">
                                    <div class="price-wrp pt-4">
                                        <span class="dollar-sign">$</span>129<span class="cents">.95</span>
                                    </div>
                                    <div class="savings-wrp">
                                        <h3>Save 35%</h3>
                                        <p>vs. the 6-month plan</p>
                                    </div>
                                </div><!--col-6-->

                                <div class="col-sm-6 col-12 pt-3">
                                    <div class="details-wrp pe-4">
                                        <p>
                                            This is a great value for students in longer programs - tackling the next degree in their field.
                                        </p>
                                    </div>
                                    
                                    <div class="button-wrp pe-0 pe-sm-4 d-none d-sm-grid" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>
                                    
                                    <div class="button-wrp px-5 d-grid d-sm-none" v-if="isShowButtons">
                                        <a :href="$outLinkUrl + 'cart-step-1'" class="btn btn-success">
                                            GET STARTED
                                        </a>
                                    </div>
                                </div><!--col-6-->
                            </div><!--row-->
                        </div><!--col-price-block-->
                    </div><!--g-col-12-->
                </div><!--row-->
            </div>

            <div class="divider mb-0" v-if="isShowPrices"/>

            <section v-if="isShowTestimonialVideo">
                <main class="container-text mt-1">
                    <div class="row mt-3">
                        <div class="col-12 col-md-6">
                            <h2 class="text-center">
                                Hear what <em>real</em> students have to say about PERRLA
                            </h2>

                            <p>
                                Every year students face the anxiety and stress of writing papers. Helping students get through the challenges of writing papers is our mission.
                            </p>
                            <p>
                                We get a lot of satisfaction helping students overcome those fears and become successful writers so they can get through school to start the next chapter in their lives.
                            </p>
                            <router-link
                                :to="{
                                    name: 'Testimonials'
                                }"
                                style="font-size: 1.2rem;">
                                See more testimonials.
                            </router-link>
                        </div>

                        <div class="col-12 col-md-6">
                            <div class="ratio ratio-1x1">
                                <iframe
                                    allow="autoplay; encrypted-media"
                                    allowfullscreen=""
                                    src="https://www.youtube-nocookie.com/embed/taKK3OGfXSw?rel=0&amp;controls=1&amp;autoplay=1&amp;mute=1&amp;start=0"
                                    style="width:100%; height:100%; pointer-events:auto"
                                    title="What real students say about PERRLA" />
                            </div>
                        </div>
                    </div>
                    
                    <div class="divider d-none d-md-block" />

                </main>
            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <h3>Every PERRLA subscription includes PERRLA for Word, PERRLA Online, &amp; our Chrome Extension!</h3>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png" class="icon " role="presentation" />
                                </div>
                                <h3>PERRLA&nbsp;for&nbsp;Word</h3>
                                <p class="text-start mb-0">
                                    PERRLA&#39;s Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.
                                </p>
                            </main>
                        </div>
                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png" class="icon" role="presentation" />
                                </div>
                                <h3>PERRLA&nbsp;Online</h3>
                                <p class="text-start mb-0">
                                    PERRLA Online is our web-based application that allows you to write APA, MLA, &amp; Turabian papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper&#39;s research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.
                                </p>
                            </main>
                        </div>
                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg" class="icon" role="presentation" />
                                </div>
                                <h3>Google Chrome Extension</h3>
                                <p class="text-start mb-0">
                                    Our Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.
                                </p>
                            </main>
                        </div>

                    </div>
                </div>
            </section>

            <section class="py-0">
                <div class="container">
                    <hr />
                </div>
            </section>

            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title">
                                    Latest APA &amp;&nbsp;MLA&nbsp;formats
                                </h3>
                                <p class="text-start">
                                    PERRLA is 100% compatible with APA 7, MLA 9, and Turabian 9 formatting guidelines. We monitor all formatting updates to make sure PERRLA is always up-to-date!
                                </p>
                                <p>&nbsp;</p>
                            </main>
                        </div>

                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title">
                                    US-based Support
                                </h3>
                                <p class="text-start">
                                    Our Customer Support team is serious about helping students. They&rsquo;re available <strong>every day until 10pm Central</strong>.
                                </p>
                            </main>
                        </div>

                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title">
                                    Formats your entire paper
                                </h3>
                                <p class="text-start">
                                    PERRLA formats your references, page margins, headings &amp; text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don&rsquo;t have to.
                                </p>
                            </main>
                        </div>

                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title ">
                                    Auto-fill Reference Wizard
                                </h3>
                                <p class="text-start">
                                    Use our Auto-fill tool to search for books, journal articles, &amp; websites without any typing. Manually create every other reference type with our guided Reference Wizard.
                                </p>
                            </main>
                        </div>

                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title">
                                    Reference Syncing
                                </h3>
                                <p class="text-start">
                                    PERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.
                                </p>
                                <p>&nbsp;</p>
                            </main>
                        </div>

                        <div class="col-12 col-md-4">
                            <main>
                                <div class="text-center">
                                    <img src="https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg" class="icon" role="presentation" />
                                </div>
                                <h3 class="grid-title ">
                                    PERRLA is always up-to-date
                                </h3>
                                <p class="text-start">
                                    Updating software is a pain. PERRLA updates itself automatically without you having to do a thing.
                                </p>
                            </main>
                        </div>
				    </div><!--grid-->

                </div>
            </section>

        </section>

    </div>
</template>


<script>
import config from '@/config';
export default {
	name: 'PricingGridV2',
	props:{
		isShowPrices: {
			default: true,
			type: Boolean
		},
        isShowTestimonialVideo: {
			default: true,
			type: Boolean
		},
	},
    computed:{
        isShowButtons(){
            if(config.platformId === config.enums.Platform.PCOM){
                return true;
            }
            return false;
        },
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/vars_perrla";

.pricing-grid-wrp {
    h3 {
        color: $body-color !important;
        font-size: calc(1.3rem + .6vw);
        font-weight: 500;
        line-height: 1.2;

    }
}


.col-price-block {
    border-radius: 0 0 10px 10px;
    margin-bottom: 16px;
    padding-bottom: 16px;

    &.col-free-trial {
        background-color: $primary-100;

        .tier-topper {
            background-color: $primary;
            h2{
                color: $white !important;
                font-weight: 400 !important;
                line-height: 1.2;
            }
        }
    }

    &.col-six-months {
        background-color: #eefae7;

        .tier-topper {
            background-color: #8ddb7a;
            h2{
                color: $body-color !important;
                font-weight: 400 !important;
                line-height: 1.2;
            }
        }
    }

    &.col-one-year {
        background-color: #e1f9d4;

        .tier-topper {
            background-color: #278724;
            h2{
                color: $white !important;
                font-weight: 400 !important;
                line-height: 1.2;
            }
        }
    }

    &.col-two-years {
        background-color: #d5f8c2;

        .tier-topper {
            background-color: #12611a;
            h2{
                color: $white !important;
                font-weight: 400 !important;
                line-height: 1.2;
            }
        }
    }

}//e:.col-price-block





.tier-topper {
    border-radius: 10px 10px 0 0;
    padding: 16px 0 8px 0;
    
    h2 {
        font-size: 24px;
        font-weight: 400;
    }
}

.price-wrp {
    font-size: 64px;
    font-weight: 400;
    line-height: 80px;
    padding-top: 45px;
    text-shadow: rgba(0, 0, 0, 0.32) 1px 1px 4px;

    @include media-breakpoint-down(lg) {
        line-height: 60px;
    }

    span.dollar-sign{
        bottom:32px;
        font-size: 24px;
        font-weight: 400;
        position: relative;

    }
    
    span.cents{
        bottom:37px;
        font-size: 20px;
        font-weight: 400;
        position: relative;
    }
}

.savings-wrp {
    margin-bottom: 32px;

    h3 {
        color: #c0392b !important;
        font-size: 20px;
        margin-bottom: 0;
        line-height: 24px;
        font-weight: 600;

        @include media-breakpoint-down(lg) {
            font-size: 24px;
        }

    }

    p {
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            font-size: 16px;
        }
    }
}

.details-wrp {
    text-align: left;
    margin-bottom: 16px;
    p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        padding: 0 8px;
    }
}

.button-wrp {
    padding-top: 16px;
    margin-bottom: 8px;

    

    a.btn {
        box-shadow: 0 1px 8px #06162466;
        font-size: 16px;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
           font-size: 24px;
        }

        &.btn-outline-primary{
            background-color: #fff;
            
            &:hover{
                color: $primary-700;
            }
        }
    }

}
</style>