var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: ["form-group", { row: _vm.isHorizontal }] }, [
    _c("div", { class: { "col-sm-3": _vm.isHorizontal } }, [
      _c("label", {
        class: { "fst-italic": _vm.isInvalidShown },
        attrs: { for: _vm.safeId },
        domProps: { innerHTML: _vm._s(_vm.label) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { class: { "col-sm-9": _vm.isHorizontal } }, [
      _vm.helpText
        ? _c("div", {
            staticClass: "help-text mb-1",
            domProps: { innerHTML: _vm._s(_vm.helpText) },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          ref: "focusElement",
          class: [
            "form-control border-end-0",
            {
              "is-invalid": _vm.isInvalidShown,
              "form-control-warning": _vm.isShowSuggestionCapsLock,
            },
          ],
          attrs: {
            autocomplete: "off",
            id: _vm.safeId,
            placeholder: _vm.placeholder,
            type: _vm.localType,
          },
          domProps: { value: _vm.value },
          on: {
            keydown: _vm.checkCapsLock,
            blur: function ($event) {
              return _vm.blurInput($event.target.value)
            },
            input: function ($event) {
              return _vm.typeInput($event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            class: [
              "input-group-text input-group-text-password",
              {
                "form-control-warning": _vm.isShowSuggestionCapsLock,
              },
            ],
          },
          [
            _c("span", {
              class: [
                "svg svg-toggle-password",
                {
                  "bi-eye": !_vm.isPasswordVisible,
                  "bi-eye-slash": _vm.isPasswordVisible,
                },
              ],
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.togglePassword.apply(null, arguments)
                },
              },
            }),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isInvalidShown
        ? _c("div", { staticClass: "invalid-feedback d-block mt-1" }, [
            _vm._v("\n\t\t\t" + _vm._s(_vm.invalidErrorMessage) + "\n\t\t"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isShowSuggestionCapsLock
        ? _c(
            "div",
            {
              key: "suggestedFeedbackCapsLockOn",
              staticClass: "suggested-feedback",
            },
            [_vm._v("\n\t\t\tCaps Lock is ON\n\t\t")]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }