var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "sidemenu-wrp d-flex align-items-stretch",
        { open: _vm.$store.state.paperEdit.toolDrawer.isSideMenuOpen },
      ],
    },
    [
      _c("div", { staticClass: "w-100 d-block" }, [
        _c(
          "a",
          {
            staticClass: "close-sidemenu",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.closeMenu.apply(null, arguments)
              },
            },
          },
          [
            _c("span", {
              staticClass: "svg bi-x-lg",
              attrs: { title: "Close Menu" },
            }),
            _vm._v(" CLOSE\n\t\t"),
          ]
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _vm.isEditor
          ? _c(
              "div",
              { key: "divEditorNavitation" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "EditorTitlePage" } },
                  },
                  [
                    _vm._v("\n\t\t\t\tTitle "),
                    _vm.documentType ===
                      _vm.$enums.DocumentType.RESEARCH_PAPER &&
                    (_vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                      _vm.$enums.Format.APA6 ||
                      _vm.$store.state.paperEdit.meta.PaperFormatVersionID ===
                        _vm.$enums.Format.APA7)
                      ? _c("span", [_vm._v("Page")])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkAbstract",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorAbstract" } },
                      },
                      [_vm._v("Abstract")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoAbstract",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Abstract")]
                    ),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkToc",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorTableOfContents" } },
                      },
                      [_vm._v("Table of Contents")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoToc",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Table of Contents")]
                    ),
                _vm._v(" "),
                _vm.showAppendixLink
                  ? _c(
                      "div",
                      [
                        _vm.documentType ==
                        _vm.$enums.DocumentType.RESEARCH_PAPER
                          ? _c(
                              "router-link",
                              {
                                key: "linkAppendix",
                                staticClass: "router-link",
                                attrs: { to: { name: "EditorAppendices" } },
                              },
                              [_vm._v("Appendix")]
                            )
                          : _c(
                              "a",
                              {
                                key: "linkNoAppendix",
                                staticClass: "router-link disabled",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                  },
                                },
                              },
                              [_vm._v("Appendix")]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "EditorReferencesCitations" } },
                  },
                  [_vm._v("\n\t\t\t\tReferences & Citations\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "EditorResearchNotes" } },
                  },
                  [_vm._v("\n\t\t\t\tResearch Notes\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "EditorOutline" } },
                  },
                  [_vm._v("\n\t\t\t\tOutline\n\t\t\t")]
                ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkInsertFigure",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorInsertFigure" } },
                      },
                      [_vm._v("Insert Figure")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoInsertFigure",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Insert Figure")]
                    ),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkInsertTable",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorInsertTable" } },
                      },
                      [_vm._v("Insert Table")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoInsertTable",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Insert Table")]
                    ),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkPaperReview",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorPaperReview" } },
                      },
                      [_vm._v("Paper Review")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoPaperReview",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Paper Review")]
                    ),
                _vm._v(" "),
                _vm.documentType == _vm.$enums.DocumentType.DISCUSSION_POST ||
                _vm.documentType == _vm.$enums.DocumentType.RESEARCH_PAPER
                  ? _c(
                      "router-link",
                      {
                        key: "linkCreateCleanCopy",
                        staticClass: "router-link",
                        attrs: { to: { name: "EditorCreateCleanCopy" } },
                      },
                      [_vm._v("Create Clean Copy")]
                    )
                  : _c(
                      "a",
                      {
                        key: "linkNoCreateCleanCopy",
                        staticClass: "router-link disabled",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                          },
                        },
                      },
                      [_vm._v("Create Clean Copy")]
                    ),
              ],
              1
            )
          : _c(
              "div",
              { key: "divOutlineNavitation" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "OutlineReferencesCitations" } },
                  },
                  [_vm._v("\n\t\t\t\tReferences & Citations\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "router-link",
                    attrs: { to: { name: "OutlineResearchNotes" } },
                  },
                  [_vm._v("\n\t\t\t\tResearch Notes\n\t\t\t")]
                ),
              ],
              1
            ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }