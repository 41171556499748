var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "mx-auto pricing-grid-wrp",
      staticStyle: { "max-width": "960px" },
    },
    [
      _c("section", [
        _vm.isShowPrices
          ? _c("div", { staticClass: "container d-none d-lg-block mt-5" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center col-free-trial col-price-block",
                    },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _vm._m(1),
                      _vm._v(" "),
                      _vm._m(2),
                      _vm._v(" "),
                      _vm._m(3),
                      _vm._v(" "),
                      _vm.isShowButtons
                        ? _c("div", { staticClass: "button-wrp" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-outline-primary",
                                attrs: {
                                  href: _vm.$outLinkUrl + "cart-step-1",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    TRY IT TODAY\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center col-six-months col-price-block",
                    },
                    [
                      _vm._m(4),
                      _vm._v(" "),
                      _vm._m(5),
                      _vm._v(" "),
                      _vm._m(6),
                      _vm._v(" "),
                      _vm._m(7),
                      _vm._v(" "),
                      _vm.isShowButtons
                        ? _c("div", { staticClass: "button-wrp" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-success",
                                attrs: {
                                  href: _vm.$outLinkUrl + "cart-step-1",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    GET STARTED\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    { staticClass: "text-center col-one-year col-price-block" },
                    [
                      _vm._m(8),
                      _vm._v(" "),
                      _vm._m(9),
                      _vm._v(" "),
                      _vm._m(10),
                      _vm._v(" "),
                      _vm._m(11),
                      _vm._v(" "),
                      _vm.isShowButtons
                        ? _c("div", { staticClass: "button-wrp" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-success",
                                attrs: {
                                  href: _vm.$outLinkUrl + "cart-step-1",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    GET STARTED\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-3" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-center col-two-years col-price-block",
                    },
                    [
                      _vm._m(12),
                      _vm._v(" "),
                      _vm._m(13),
                      _vm._v(" "),
                      _vm._m(14),
                      _vm._v(" "),
                      _vm._m(15),
                      _vm._v(" "),
                      _vm.isShowButtons
                        ? _c("div", { staticClass: "button-wrp" }, [
                            _c(
                              "a",
                              {
                                staticClass: "btn btn-success",
                                attrs: {
                                  href: _vm.$outLinkUrl + "cart-step-1",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                    GET STARTED\n                                "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowPrices
          ? _c(
              "div",
              {
                staticClass: "d-block d-lg-none mx-auto",
                staticStyle: { "max-width": "728px" },
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center col-free-trial col-price-block",
                      },
                      [
                        _vm._m(16),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(17),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6 col-12 pt-3" }, [
                            _vm._m(18),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp pe-0 pe-sm-4 d-none d-sm-grid",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-outline-primary",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            TRY IT TODAY\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp px-5 d-grid d-sm-none",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-outline-primary",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            TRY IT TODAY\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center col-six-months col-price-block",
                      },
                      [
                        _vm._m(19),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(20),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6 col-12 pt-3" }, [
                            _vm._m(21),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp pe-0 pe-sm-4 d-none d-sm-grid",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp px-5 d-grid d-sm-none",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass: "text-center col-one-year col-price-block",
                      },
                      [
                        _vm._m(22),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(23),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6 col-12 pt-3" }, [
                            _vm._m(24),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp pe-0 pe-sm-4 d-none d-sm-grid",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp px-5 d-grid d-sm-none",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "g-col-12" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-center col-two-years col-price-block",
                      },
                      [
                        _vm._m(25),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _vm._m(26),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6 col-12 pt-3" }, [
                            _vm._m(27),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp pe-0 pe-sm-4 d-none d-sm-grid",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isShowButtons
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "button-wrp px-5 d-grid d-sm-none",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "btn btn-success",
                                        attrs: {
                                          href: _vm.$outLinkUrl + "cart-step-1",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            GET STARTED\n                                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowPrices
          ? _c("div", { staticClass: "divider mb-0" })
          : _vm._e(),
        _vm._v(" "),
        _vm.isShowTestimonialVideo
          ? _c("section", [
              _c("main", { staticClass: "container-text mt-1" }, [
                _c("div", { staticClass: "row mt-3" }, [
                  _c(
                    "div",
                    { staticClass: "col-12 col-md-6" },
                    [
                      _vm._m(28),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                                Every year students face the anxiety and stress of writing papers. Helping students get through the challenges of writing papers is our mission.\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n                                We get a lot of satisfaction helping students overcome those fears and become successful writers so they can get through school to start the next chapter in their lives.\n                            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "router-link",
                        {
                          staticStyle: { "font-size": "1.2rem" },
                          attrs: {
                            to: {
                              name: "Testimonials",
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                                See more testimonials.\n                            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._m(29),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "divider d-none d-md-block" }),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._m(30),
        _vm._v(" "),
        _vm._m(31),
        _vm._v(" "),
        _vm._m(32),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("Free Trial")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price-wrp" }, [
      _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
      _vm._v("0\n                            "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "savings-wrp" }, [
      _c("h3", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp" }, [
      _c("p", [
        _vm._v("\n                                    No restrictions!"),
        _c("br"),
        _vm._v("\n                                    No payment required!"),
        _c("br"),
        _vm._v(
          "\n                                    7 Days to try PERRLA completely free."
        ),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("6 months")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price-wrp" }, [
      _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
      _vm._v("49"),
      _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "savings-wrp" }, [
      _c("h3", [_vm._v(" ")]),
      _vm._v(" "),
      _c("p", [_vm._v(" ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp" }, [
      _c("p", [
        _vm._v(
          "\n                                    Access to all our best products for students in shorter, semester long programs.\n                                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("1 year")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price-wrp" }, [
      _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
      _vm._v("79"),
      _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "savings-wrp" }, [
      _c("h3", [_vm._v("Save 20%")]),
      _vm._v(" "),
      _c("p", [_vm._v("vs. the 6-month plan")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp" }, [
      _c("p", [
        _vm._v("\n                                    Our "),
        _c("i", [_vm._v("most popular")]),
        _vm._v(" choice for all students."),
        _c("br"),
        _vm._v(
          "\n                                    Access to all our best products for the entire year!\n                                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("2 years")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "price-wrp" }, [
      _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
      _vm._v("129"),
      _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "savings-wrp" }, [
      _c("h3", [_vm._v("Save 35%")]),
      _vm._v(" "),
      _c("p", [_vm._v("vs. the 6-month plan")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp" }, [
      _c("p", [
        _vm._v(
          "\n                                    This is a great value for students in longer programs - tackling the next degree in their field.\n                                "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("Free Trial")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-12" }, [
      _c("div", { staticClass: "price-wrp pt-4" }, [
        _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
        _vm._v("0\n                                    "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp pe-4" }, [
      _c("p", [
        _vm._v(
          "\n                                            No restrictions!"
        ),
        _c("br"),
        _vm._v(
          "\n                                            No payment required!"
        ),
        _c("br"),
        _vm._v(
          "\n                                            7 Days to try PERRLA completely free."
        ),
        _c("br"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("6 months")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-12" }, [
      _c("div", { staticClass: "price-wrp pt-4" }, [
        _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
        _vm._v("49"),
        _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp pe-4" }, [
      _c("p", [
        _vm._v(
          "\n                                            Access to all our best products for students in shorter, semester long programs.\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("12 months")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-12" }, [
      _c("div", { staticClass: "price-wrp pt-4" }, [
        _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
        _vm._v("79"),
        _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "savings-wrp" }, [
        _c("h3", [_vm._v("Save 20%")]),
        _vm._v(" "),
        _c("p", [_vm._v("vs. the 6-month plan")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp pe-4" }, [
      _c("p", [
        _vm._v("\n                                            Our "),
        _c("i", [_vm._v("most popular")]),
        _vm._v(" choice for all students."),
        _c("br"),
        _vm._v(
          "\n                                            Access to all our best products for the entire year!\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tier-topper" }, [
      _c("h2", [_vm._v("2 years")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-6 col-12" }, [
      _c("div", { staticClass: "price-wrp pt-4" }, [
        _c("span", { staticClass: "dollar-sign" }, [_vm._v("$")]),
        _vm._v("129"),
        _c("span", { staticClass: "cents" }, [_vm._v(".95")]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "savings-wrp" }, [
        _c("h3", [_vm._v("Save 35%")]),
        _vm._v(" "),
        _c("p", [_vm._v("vs. the 6-month plan")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "details-wrp pe-4" }, [
      _c("p", [
        _vm._v(
          "\n                                            This is a great value for students in longer programs - tackling the next degree in their field.\n                                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "text-center" }, [
      _vm._v("\n                                Hear what "),
      _c("em", [_vm._v("real")]),
      _vm._v(
        " students have to say about PERRLA\n                            "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12 col-md-6" }, [
      _c("div", { staticClass: "ratio ratio-1x1" }, [
        _c("iframe", {
          staticStyle: {
            width: "100%",
            height: "100%",
            "pointer-events": "auto",
          },
          attrs: {
            allow: "autoplay; encrypted-media",
            allowfullscreen: "",
            src: "https://www.youtube-nocookie.com/embed/taKK3OGfXSw?rel=0&controls=1&autoplay=1&mute=1&start=0",
            title: "What real students say about PERRLA",
          },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("h3", [
              _vm._v(
                "Every PERRLA subscription includes PERRLA for Word, PERRLA Online, & our Chrome Extension!"
              ),
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(" ")]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-ms-word.png",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("PERRLA for Word")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start mb-0" }, [
                _vm._v(
                  "\n                                    PERRLA's Add-in for Microsoft Word runs directly inside Word. It does all the formatting for you. So, write your content with Word and allow PERRLA to create your references and citations.\n                                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/big-online.png",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("PERRLA Online")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start mb-0" }, [
                _vm._v(
                  "\n                                    PERRLA Online is our web-based application that allows you to write APA, MLA, & Turabian papers from any computer. It has all our paper-writing functionality, plus the ability to organize your paper's research notes and outline. It also offers a number of tools for tracking your schoolwork and calendar.\n                                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/chrome.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", [_vm._v("Google Chrome Extension")]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start mb-0" }, [
                _vm._v(
                  "\n                                    Our Google Chrome web extension lets you create new references, access your Reference Library, and create new citations from anywhere on the web.\n                                "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "py-0" }, [
      _c("div", { staticClass: "container" }, [_c("hr")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/book.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    Latest APA & MLA formats\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    PERRLA is 100% compatible with APA 7, MLA 9, and Turabian 9 formatting guidelines. We monitor all formatting updates to make sure PERRLA is always up-to-date!\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/help.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    US-based Support\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    Our Customer Support team is serious about helping students. They’re available "
                ),
                _c("strong", [_vm._v("every day until 10pm Central")]),
                _vm._v(".\n                                "),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/document.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    Formats your entire paper\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    PERRLA formats your references, page margins, headings & text styles, table of contents, abstracts, reference section, tables, figures, and citations. We do it all so you don’t have to.\n                                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/lookup.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    Auto-fill Reference Wizard\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    Use our Auto-fill tool to search for books, journal articles, & websites without any typing. Manually create every other reference type with our guided Reference Wizard.\n                                "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/bookshelf.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    Reference Syncing\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    PERRLA automatically syncs your references across computers so you can access your entire reference library from anywhere.\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(" ")]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c("main", [
              _c("div", { staticClass: "text-center" }, [
                _c("img", {
                  staticClass: "icon",
                  attrs: {
                    src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/ico2022/up-to-date.svg",
                    role: "presentation",
                  },
                }),
              ]),
              _vm._v(" "),
              _c("h3", { staticClass: "grid-title" }, [
                _vm._v(
                  "\n                                    PERRLA is always up-to-date\n                                "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "text-start" }, [
                _vm._v(
                  "\n                                    Updating software is a pain. PERRLA updates itself automatically without you having to do a thing.\n                                "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }