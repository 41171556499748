import "core-js/stable";
import "regenerator-runtime/runtime";

import App from '@/App';
import config from '@/config';
import Dropdown from 'bootstrap/js/dist/dropdown'; // importing here so it can build inside the main app chunk, it will be grayed out
import mitt from 'mitt';
import Rollbar from 'rollbar';
import router from '@/router';
import store from '@/store';
import styles from  '@/assets/styles/app.scss';	// the name will automatically import these, it's ok it's greyed out
import Vue from 'vue';
import VueDebounce from 'vue-debounce';
import VueHotkey from 'v-hotkey';
import VueGtag from "vue-gtag"; // https://github.com/MatteoGabriele/vue-gtag

const emitter = mitt();

Vue.config.devtools = false;
Vue.config.productionTip = false;

if(!config.isDev){
	console.log(VERSION);
}

Vue.use(VueDebounce);
Vue.use(VueHotkey);

// Adds Target Blank to a link inside reference output
Vue.directive('add-blank-target', {
	inserted(el){
		// regEx for href conetent
		const isLinkInContent = RegExp('href="([^\'\"]+)');
		
		// Only if there is a link in the content
		if(isLinkInContent.test(el.innerHTML)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(el.innerHTML, 'text/html');
			let aElements = parsedHtml.getElementsByTagName("a");
			
			// double check to make sure this is really an <a>
			if(aElements && aElements[0]){
				aElements[0].setAttribute('target', '_blank');
			}
			el.innerHTML = parsedHtml.body.innerHTML;
		}
	},
	update(el) {
		// regEx for href conetent
		const isLinkInContent = RegExp('href="([^\'\"]+)');
		
		// Only if there is a link in the content
		if(isLinkInContent.test(el.innerHTML)){
			let parser = new DOMParser();
			let parsedHtml = parser.parseFromString(el.innerHTML, 'text/html');
			let aElements = parsedHtml.getElementsByTagName("a");
			
			// double check to make sure this is really an <a>
			if(aElements && aElements[0]){
				aElements[0].setAttribute('target', '_blank');
			}
			el.innerHTML = parsedHtml.body.innerHTML;
		}
	}
});//e:add-blank-target

// Convert config settings to Vue instance properties (to use in vue templates)
Vue.prototype.$enums = config.enums;
Vue.prototype.$isDev = config.isDev;
Vue.prototype.$isTest = config.isTest;
Vue.prototype.$isLive = config.isLive;
Vue.prototype.$platformId = config.platformId;
Vue.prototype.$version = VERSION;
Vue.prototype.emitter = emitter;

// Google Analytics
if(!config.isDev){
	Vue.use(VueGtag, {
		config: { id: "UA-8625624-8" },
		globalObjectName: 'PERRLAGtag',
		params: {
			send_page_view: false
		},
	}, router);
}


// Rollbar
if(config.useErrorLogger){
	Vue.prototype.$rollbar = new Rollbar({
		accessToken: '1c357151fbde437790bde8cd05698beb',
		captureUncaught: true,
		captureUnhandledRejections: true,
		
		// trying to isolate ckeditor.js but no success
		// checkIgnore: function(isUncaught, args, payload) {
		// 	console.log('payload');
		// 	console.log(payload);

		// 	// if (payload.filepath === 'path/to/your/specific/file.js') {
		
		// 	//   return true; // Ignore errors from this file
		
		// 	// }
		
		// 	return false; // Send other errors to Rollbar
		
		// },
		
		

		enabled: true,
		environment: (config.isLive) ? "production" : "staging",
		filterTelemetry: function(e) {
			return e.type === 'network'
				&& e.body.subtype === 'xhr'
				&& (
					e.body.url.indexOf('https://bam') === 0 || 
					e.body.url.indexOf('https://stats') === 0 ||
					e.body.url.indexOf('https://svc') === 0 || 
					e.body.url.indexOf('https://www.google') === 0 || 
					e.body.url.indexOf('https://ekr.zdassets') === 0 
				);
		},
		hostSafeList: ['perrlacomplete.com', 'perrla.com'],
		// Characters that may need to be escaped include: ^, $, ., |, ?, *, +, (), [], and {}. In order for these to remain escaped in the regex matcher, they should be doubled escaped when passed in the config.
		ignoredMessages: [
			".*getModifierState.*", // using the period here as a wildcard - don't double escape it
			". is null", // using the period here as a wildcard - don't double escape it
			"b\\.getChildren\\(\\.\\.\\.\\)\\.getItem\\(\\.\\.\\.\\)\\.getName is not a function",
			"Can't find variable WEBSPELLCHECKER",
			"Cannot read properties of undefined \\(reading 'checkReadOnly'\\)",
			"Cannot read properties of undefined \\(reading 'collapsed'\\)",
			"Cannot read properties of undefined \\(reading 'getSelection'\\)",
			"Cannot read properties of undefined \\(reading 'parentNode'\\)",
			"Cannot read properties of null \\(reading 'compare'\\)",
			"Cannot read properties of null \\(reading 'getProblemInfo'\\)",
			"Cannot read properties of null \\(reading 'getParent'\\)",
			"Cannot read properties of null \\(reading 'getParents'\\)",
			"Cannot read properties of null \\(reading 'insertBefore'\\)",
			"Cannot read properties of null \\(reading 'register'\\)",
			"Cannot read properties of null \\(reading 'type'\\)",
			"Cannot read properties of null \\(reading 'unselectable'\\)",
			"Cannot read properties of undefined \\(reading 'node'\\)",
			"Cannot read property 'type' of null",
			"Cannot set properties of null \\(setting 'previous'\\)",
			"Cannot set properties of null \\(setting 'register'\\)",
			"CKEDITOR\\.cleanWord is not a function",
			"CKEDITOR\\.pasteFilters\\.gdocs is not a function",
			"Extension context invalidated\\.",
			"Failed to execute .*",
			"HierarchyRequestError",
			"Item sent with null or missing arguments\\.",
			"Node cannot be used in a document other than the one in which it was created",
			"null is not an object \\(evaluating 'a\\.getParent'\\)",
			"null is not an object \\(evaluating 'b\\.getService\\(\"Deferred\"\\)\\.register'\\)",
			"null is not an object \\(evaluating 'b\\.getService\\(\"ProblemManager\"\\)\\.getProblemInfo'\\)",
			"null is not an object \\(evaluating 'a\\.previous=this.previous'\\)",
			"null is not an object \\(evaluating 'b\\.type'\\)",
			"Please use show on visible elements",
			"ResizeObserver loop completed with undelivered notifications\\.",
			"ResizeObserver loop limit exceeded",
			"Script error",
			"The index is not in the allowed range\\.",
			"The operation would yield an incorrect node tree\\.",
			"TypeError: Cannot read properties of null \\(reading 'register'\\)",
			"TypeError: Cannot read properties of null \\(reading 'compare'\\)",
			"TypeError: null is not an object \\(evaluating 'b\\.type'\\)",
			"TypeError: CKEDITOR\\.pasteFilters\\.gdocs is not a function",
			"undefined is not an object \\(evaluating 'p\\.checkReadOnly'\\)",
			"window\\.grecaptcha\\.render is not a function",
			"WrongDocumentError"
		],
		payload: {
			client: {
				javascript: {
					code_version: VERSION
				}
			}
		},
		source_map_enabled: (config.isLive) ? true : false,
		verbose: true,
   });
}

// WProofreader
if(config.useWProofreader){
	window.WEBSPELLCHECKER_CONFIG = {
		autoDestroy: true,
		autoSearch: false,
		disableBadgePulsing: true,
		disabledRules: ['16309503562252323748'], // i think this will prevent "Dr. Sprott said her" from suggesting HER should be HIM
		lang: 'en_US',
		ignoreElements: 'span',
		settingsSections: ['dictionaries', 'options'],
		serviceId: '1:b9y8Ah-vyKSQg-Qqh1f2-lXQgJ-obMz61-iJGmM-xXk4a-HPV2Hg-rnxj62-yhc',
		theme: 'gray',
	};
}

// Vue Error Handler
Vue.config.errorHandler = (error, vm, info) => {
	if(config.useErrorLogger){
		vm.$rollbar.error(error);
	} else {
		console.group('Vue Error');
		console.log(error);
		console.log(info);
		console.groupEnd();
	}
	
	

	if(window.newrelic){
		// Send Vue error to new relic
		if(!config.errorsToIgnore['SPA'].includes(error.message)){
			console.log('Send Error To New Relic');
			newrelic.noticeError(error);
		}
	}

	throw error; // rethrow
};

// ZenDesk Widget positioning 
window.zESettings = {
	webWidget: {
		offset: {
			horizontal: '0px',
			vertical: '12px'
		},
	}
}

// after route changes, some users have a url set on the old way:
// App?encryptedPaperId=MYhRRnRIPHlsb1CteImYHw==/#/editor/references-citations
// use that information and gently redirect them

if(config.isDev && window.location.pathname === '/'){
	// Dev for TempPack src project (not in visual studio)
	window.$vm = new Vue({
		render: (createEL) => createEL(App),
		computed: {
			isNavSmall() {
				return true; // force small version of sidebar
			}
		},
		router,
		store,
	}).$mount('#app');

} else {
	// Test/Live or Dev in Visual Studio with the already build/dist project
	let urlParams = new URLSearchParams(window.location.search);
	// console.log('urlParams');
	// console.log(urlParams);
	
	let resolveEncryptedPaperId = urlParams.get('encryptedPaperId');
	if(resolveEncryptedPaperId){
		resolveEncryptedPaperId = resolveEncryptedPaperId.replace('/','');
		// console.log('resolveEncryptedPaperId');
		// console.log(resolveEncryptedPaperId);
		window.location = '/App/#/paper/' + resolveEncryptedPaperId + '/editor/references-citations';
	} else {

		window.$vm = new Vue({
			render: (createEL) => createEL(App),
			computed: {
				isNavSmall() {
					return true; // force small version of sidebar
				}
			},
			// watch: {
			// 	$route: {
			// 		handler: function() {
			// 			if(window.userpilot && config.isTest){
			// 				// console.log('user pilot on - reload');
			// 				userpilot.reload();
			// 			}
						
			// 		},
			// 		deep: true,
			// 		immediate: true
			// 	}
			// },
			router,
			store,
		}).$mount('#app');
	}
}
