<!--
- Generic Modal > Shared References Received (Archived)
-- https://docs.google.com/document/d/1SEYz5JUk6alwIDEUlPhFhvf4L-RubSE07eVOxGIy6AQ/edit?tab=t.0#heading=h.n5rm5138r62r
-- the archived part means this modal came from /references-shared-with-you 
-->

<template>
	<generic-modal-wrap modal-id="SharedReferencesReceivedArchived">
		<h2 class="nodal-title">
			You've got shared references!
		</h2>

		<div v-if="passData" key="divSharedReferenceInfo">
			<p>
				<strong>It looks like {{passData.firstName}} has shared {{ passData.references.length }} of their references with you.</strong>
					If you add the references to your PERRLA account, we'll create a copy in your library so you to use them in your own papers.</p>
			
			<p v-if="passData.message.length > 0">{{passData.firstName}} sent a message along with the references:</p>
			
			<div class="message-wrp word-break" v-if="passData.message.length > 0">
				<p>
					{{ passData.message }}
				</p>
			</div>

			<div v-if="passData.status === $enums.RefShareInvitationStatus.DECLINED || passData.status === $enums.RefShareInvitationStatus.PENDING" key="divReferencesPendingOrDeclined">
				<h2 class="mb-3">Do you want to copy these reference to your Reference Library?</h2>

				<div class="row">
					<div class="col">
						<div class="d-grid">
							<button type="button" class="btn btn-outline-danger" @click.prevent="btnIgnore">
								Decline Shared References
							</button>
						</div>
					</div>
					<div class="col">
						<div class="d-grid">
							<button type="button" class="btn btn-success" @click.prevent="btnAccept">
								Add References to My Library
							</button>
						</div>
					</div>
				</div>
			</div><!--divReferencesPendingOrDeclined-->
			<div v-else>
				<div class="col">
					<div class="d-grid">
						<button type="button" class="btn btn-success" @click.prevent="btnCloseModal">
							Already added to library
						</button>
					</div>
				</div>
			</div>
		</div>

	</generic-modal-wrap>
</template>

<script>
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ModalSharedReferencesReceivedArchived',
	computed:{
		passData(){
			if(this.$store.state.modals.showArray.includes('SharedReferencesReceivedArchived') && !_isEmpty(this.$store.state.modals.passData)){
				return this.$store.state.modals.passData;
			}
			return null;
		},

	},
	methods:{
		btnAccept(){
			this.$store.commit('loaders/ADD_ID', 'App');
			console.log('btnAccept');

			let numberOfRefernecesAdded = this.passData.references.length;

			ApiS_MakeCall({
				params:{
					'shareRequestID': this.passData.genesisReferenceShareRequestID,
				},
				method: 'PUT',
				url: 'v4/GenesisReference/Share/Accept',
			}).then(()=>{
				
				this.emitter.emit('updateInvitationList');

				this.$store.commit('modals/CLOSE', 'SharedReferencesReceivedArchived');

				this.$store.commit('loaders/REMOVE_ID', 'App');

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Shared references added to your library',
				});
			});//e:ApiS_MakeCall
			
		},//e:btnAccept

		btnCloseModal(){
			this.$store.commit('modals/CLOSE', 'SharedReferencesReceivedArchived');
		},

		btnIgnore(){
			this.$store.commit('loaders/ADD_ID', 'App');
			
			ApiS_MakeCall({
				params:{
					'shareRequestID': this.passData.genesisReferenceShareRequestID,
				},
				method: 'PUT',
				url: 'v4/GenesisReference/Share/Decline',
			}).then(()=>{
				
				this.emitter.emit('updateInvitationList');

				this.$store.commit('modals/CLOSE', 'SharedReferencesReceivedArchived');

				this.$store.commit('loaders/REMOVE_ID', 'App');

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Shared references ignored',
				});
			});//e:ApiS_MakeCall
			
		},//e:btnIgnore
	},
	components:{
		GenericModalWrap
	},
}
</script>

<style lang="css" scoped>
h2 {
	font-size: 1.3rem;
	font-weight: 600;
}
.message-wrp{
	margin-left: 16px;
	font-weight: 300;
	font-style: italic;
}
</style>