var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      class: _vm.calcRowClasses,
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.openInvitationAlert.apply(null, arguments)
        },
      },
    },
    [
      _c("td", {
        staticClass: "td-entity-title td-entity-click",
        domProps: { innerHTML: _vm._s(_vm.calcInvitationTitle) },
      }),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c("td", { staticClass: "td-entity-click word-break" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.invitationData.message) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("td", { staticClass: "table-spacer" }),
      _vm._v(" "),
      _c("td", {
        staticClass: "td-entity-click",
        domProps: { innerHTML: _vm._s(_vm.calcInitationStatus) },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }