/*
- Cart
-- ex: state.cart.x
*/
const taxRateTN = 0.0975;

import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import { addMonths } from 'date-fns';
import config from '@/config';
import dateFormat from "dateformat";
import router from '@/router';

export default {
	namespaced: true,
	
	state: {
		// split the difference here between what comes from the api / what goes to a different api / and what are internal flags i need

		annualSubscriptionPrice: '',
		chargeTax: false,
		currentExpirationDate: '',
		customerId: 0,
		description: '',
		discount: 0, 
		grandTotal: 0,
		isAutomated: false,
		isRenewal: false,
		isSuccessful: false,
		paymentMethod: null,	// default payment identifier
		prioritySupport: false,
		productName: '',	// this gets set when a purchase is made and passed to GoogleAnalyics on the receipt
		products: [],
		promotionalCode: {},	// object : gets filled in when appling a promo code
		prices: {},
		purchaseAttempts: 0,
		referringCustomerId: 0,
		renewalPeriod: 0, // 1 - Semi / 2 - Annual
		renewalType: 0,	// 1 - Auto / 2 = Manual
		shoppingCartUserId: 0,
		subTotal: 0,	// what is used for calculations and to send to the API 
		tax: 0,
		zipCode: '',

		proRatedPrioritySupportPrice: 0,
		purchaseId: '',
		transactionId: '',
		subTotalDisplay: 0,	// what gets displayed to the user

		// specific to the payment method for this transaction only
		accountNumber: '',
		accountType: '1',	// 1 - Checking / 2 - Saving
		bankName: '',
		cardNumber: '',
		creditCardType: '',	// text returned from Cleave.js - amex mastercard visa diners discover jcb dankort instapayment uatp mir unionPay
		expirationDateOutput: '',
		expirationMonth: '',
		expirationYear: '',
		firstName: '',
		isNonUsAddress: false,
		lastName: '',
		paymentTypeId: config.enums.CartPaymentType.CARD,
		routingNumber: '',
		verificationCode: '',
	},

	
    // "": 1,
	//CartPaymentType
	
	mutations: {
		CLEAR_PAYMENT_METHOD(state){
			state.paymentMethod = null;
		},
		CLEAR_PRODUCTS_ARRAY(state){
			state.products = [];
		},
		PUSH_TO_PRODUCTS_ARRAY(state, value){
			state.products.push(value);
		},
		SET_ANNUAL_SUBSCRIPTION_PRICE(state, value){
			state.annualSubscriptionPrice = value;
		},
		SET_CHARGE_TAX(state, value){
			state.chargeTax = value;
		},
		SET_CURRENT_EXPIRATION_DATE(state, value){
			state.currentExpirationDate = value;
		},
		SET_CUSTOMER_ID(state, value){
			state.customerId = value;
		},
		SET_DESCRIPTION(state, value){
			state.description = value;
		},
		SET_DISCOUNT(state, value){
			state.discount = value;
		},
		SET_GRAND_TOTAL(state, value){
			state.grandTotal = value;
		},
		SET_IS_AUTOMATED(state, value){
			state.isAutomated = value;
		},
		SET_IS_RENEWAL(state, value){
			state.isRenewal = value;
		},
		SET_IS_SUCCESSFUL(state, value){
			state.isSuccessful = value;
		},
		SET_PAYMENT_METHOD(state, value){
			state.paymentMethod = value;
		},
		SET_PRIORITY_SUPPORT(state, value){
			state.prioritySupport = value;
		},
		SET_PRO_RATED_PRIORITY_SUPPORT_PRICE(state, value){
			state.proRatedPrioritySupportPrice = value;
		},
		SET_PRODUCT_NAME(state, value){
			state.productName = value;
		},
		SET_PRODUCTS(state, value){
			state.products = value;
		},
		SET_PROMOTIONAL_CODE(state, value){
			state.promotionalCode = value;
		},
		SET_PRICES(state, value){
			state.prices = value;
		},
		SET_PURCHASE_ATTEMPTS(state, value){
			state.purchaseAttempts = value;
		},
		SET_PURCHASE_ID(state, value){
			state.purchaseId = value;
		},
		SET_REFERRING_CUSTOMER_ID(state, value){
			state.referringCustomerId = value;
		},
		SET_RENEWAL_PERIOD(state, value){
			state.renewalPeriod = value;
		},
		SET_RENEWAL_TYPE(state, value){
			state.renewalType = value;
		},
		SET_SHOPPING_CART_USER_ID(state, value){
			state.shoppingCartUserId = value;
		},
		SET_SUBTOTAL(state, value){
			state.subTotal = value;
		},
		SET_SUBTOTAL_DISPLAY(state, value){
			state.subTotalDisplay = value;
		},
		SET_TAX(state, value){
			state.tax = value;
		},
		SET_TRANSACTION_ID(state, value){
			state.transactionId = value;
		},
		SET_ZIP_CODE(state, value){
			state.zipCode = value;
		},

				
		SET_ACCOUNT_NUMBER(state, value){
			state.accountNumber = value;
		},
		SET_ACCOUNT_TYPE(state, value){
			state.accountType = value;
		},
		SET_BANK_NAME(state, value){
			state.bankName = value;
		},
		SET_CARD_NUMBER(state, value){
			state.cardNumber = value;
		},
		SET_CREDIT_CARD_TYPE(state, value){
			// convert cleave.js card string into readable name
			let readValue = '';

			switch (value){
				case 'amex':
					readValue = 'Amex';
					break;
				case 'discover':
					readValue = 'Discover';
					break;
				case 'jcb':
					readValue = 'JCB';
					break;
				case 'mastercard':
					readValue = 'MasterCard';
					break;
				case 'visa':
					readValue = 'Visa';
					break;
			}
			state.creditCardType = readValue;
		},
		SET_EXPIRATION_DATE_OUTPUT(state, value){
			state.expirationDateOutput = value;
		},
		SET_EXPIRATION_MONTH(state, value){
			state.expirationMonth = value;
		},
		SET_EXPIRATION_YEAR(state, value){
			state.expirationYear = value;
		},
		SET_FIRST_NAME(state, value){
			state.firstName = value;
		},
		SET_LAST_NAME(state, value){
			state.lastName = value;
		},
		SET_IS_PRIORITY_SUPPORT(state, value){
		},
		SET_IS_NON_US_ADDRESS(state, value){
			state.isNonUsAddress = value;
		},
		SET_PAYMENT_TYPE_ID(state, value){
			state.paymentTypeId = value;
		},
		SET_ROUTING_NUMBER(state, value){
			state.routingNumber = value;
		},
		SET_VERIFICATION_CODE(state, value){
			state.verificationCode = value;
		},
	},

	actions:{
		// build the cart object from the vuex store state (used when i need to send the entire cart object to an api call)
		assembleObject({commit, state}) {
			return new Promise((resolve) => {
				let isCreditCard = true;
				if(state.paymentTypeId === config.enums.CartPaymentType.ECHECK){
					isCreditCard = false;
				}

				if(!state.paymentMethod){
					if(state.accountNumber === '' && state.cardNumber === ''){
						// no actual payment info has been entered yet

					} else {
						// paymentMethod is null - set it if there is anything to set

						let paymentMethodName = '';
						if(isCreditCard){
							paymentMethodName = 'Credit Card Ending In ' + state.cardNumber.slice(state.cardNumber.length - 4, state.cardNumber.length);
						} else {
							paymentMethodName = 'E-Check from Account Ending In ' + state.accountNumber.slice(state.accountNumber.length - 4, state.accountNumber.length);
						}
					
						if(isCreditCard){
							commit('SET_PAYMENT_METHOD', {
								cardNumber:  state.cardNumber.replace(/\s/g, ''),
								cardType: state.creditCardType,
								expirationMonth: state.expirationMonth.padStart(2, '0'),
								expirationYear: state.expirationYear.padStart(4, '0'),
								name: paymentMethodName,
								nameOnCard: state.firstName + ' ' + state.lastName,
								paymentMethodID: 0,	// pass this as 0 if it's a new user using a new payment method
								paymentTypeID: state.paymentTypeId,
								verificationCode: state.verificationCode.padEnd(3, '0'),
							});
						} else {
							commit('SET_PAYMENT_METHOD', {
								accountNumber: state.accountNumber,
								accountType: state.accountType,
								bankName: state.bankName,
								name: paymentMethodName,
								// nameOnCard: state.firstName + ' ' + state.lastName,
								paymentMethodID: 0,	// pass this as 0 if it's a new user using a new payment method
								paymentTypeID: state.paymentTypeId,
								routingNumber: state.routingNumber,
							});
						}
					}
				}//e:if:!paymentMethod

				// this store object will get sent to make the actual purchase
				let currentStoreObject = {
					annualSubscriptionPrice: state.annualSubscriptionPrice,
					chargeTax: state.chargeTax,
					currentExpirationDate: state.currentExpirationDate,
					customerID: state.customerId || 0,
					description: state.description,
					discount: state.discount, 
					grandTotal: state.grandTotal,
					isAutomated: state.isAutomated,
					isRenewal: state.isRenewal,
					isSuccessful: state.isSuccessful,
					paymentMethod: state.paymentMethod,
					prices: state.prices,
					prioritySupport: state.prioritySupport,
					products: state.products,
					promotionalCode: state.promotionalCode,
					purchaseAttempts: state.purchaseAttempts,
					referringCustomerID: state.referringCustomerId,
					renewalPeriod: state.renewalPeriod,
					renewalType: state.renewalType,
					shoppingCartUserID: state.shoppingCartUserId,
					subTotal: state.subTotal,
					tax: state.tax,
					zipCode: state.zipCode,
				}
				
				// console.log('currentStoreObject');
				// console.log(currentStoreObject);

				resolve(currentStoreObject);
			});//e:Promise
		},//e:assembleObject

		// gets a cart object via api call
		getNewCart({commit}, $opts) {
			return new Promise((resolve) => {
				let params = {};
				if(_has($opts, 'shoppingCartUserID')){
					params['shoppingCartUserID'] = $opts.shoppingCartUserID
				}

				// users can be legit and editing their account - or they could be a new customer - this check looks at where the request is coming from
				let isIgnoreAuth = true;
				if(router.currentRoute.name === 'AccountBilling' || router.currentRoute.name === 'MyProfile' || router.currentRoute.name === 'SubscriptionExpired'){
					isIgnoreAuth = false;
				}

				// without parameters this will use the generated token to build a shopping cart object and return it
				ApiS_MakeCall({
					ignoreAuth: isIgnoreAuth, 
					method: 'GET',
					params,
					responseType: 'json',
					url: 'v4/Purchase/Cart',
				}).then((responseData)=>{
					console.log('v4/Purchase/Cart responseData')
					console.log(responseData);

					// this response should be the "master cart" object
					commit('SET_ANNUAL_SUBSCRIPTION_PRICE', responseData.annualSubscriptionPrice);
					commit('SET_CHARGE_TAX', responseData.chargeTax);
					commit('SET_CURRENT_EXPIRATION_DATE', responseData.currentExpirationDate);
					commit('SET_CUSTOMER_ID', responseData.customerID);
					commit('SET_DESCRIPTION', responseData.description);
					commit('SET_DISCOUNT', responseData.discount);
					commit('SET_GRAND_TOTAL', responseData.grandTotal);
					commit('SET_IS_AUTOMATED', responseData.isAutomated);
					commit('SET_IS_RENEWAL', responseData.isRenewal);
					commit('SET_IS_SUCCESSFUL', responseData.isSuccessful);
					commit('SET_PAYMENT_METHOD', responseData.paymentMethod);
					commit('SET_PRIORITY_SUPPORT', responseData.prioritySupport);
					commit('SET_PRODUCTS', responseData.products);
					commit('SET_PROMOTIONAL_CODE', {});	// keep this an empty object since you are making a new cart i don't want any left over promo code data
					commit('SET_PRICES', responseData.prices);
					commit('SET_PURCHASE_ATTEMPTS', responseData.purchaseAttempts);
					commit('SET_REFERRING_CUSTOMER_ID', 0);
					commit('SET_SHOPPING_CART_USER_ID', responseData.shoppingCartUserID);
					commit('SET_SUBTOTAL', responseData.subTotal);
					commit('SET_SUBTOTAL_DISPLAY', responseData.subTotal);
					commit('SET_TAX', responseData.tax);
					commit('SET_ZIP_CODE', responseData.zipCode);
					
					if(responseData.renewalPeriod === 0){
						// 0 is none - usually for newly created accounts, nudge them into annual subscription
						commit('SET_RENEWAL_PERIOD', config.enums.SubscriptionRenewalPeriod.ANNUAL);
						commit('SET_RENEWAL_TYPE', config.enums.SubscriptionRenewalType.AUTO);
					} else {
						commit('SET_RENEWAL_PERIOD', responseData.renewalPeriod);
						commit('SET_RENEWAL_TYPE', responseData.renewalType);
					}

					resolve();
				});//e:ApiS_MakeCall
				
			});//e:Promise
		},//e:getNewCart

		// after any change, keep the cart totals updated
		updateCartTotals({commit, rootState, state}, $opts) {
			return new Promise((resolve) => {
				// console.log('state');
				// console.log(state);

				// flag for purchasing priority support only
				let _isPrioritySupportOnly = false;
				if(_has($opts, 'isPrioritySupportOnly')){
					_isPrioritySupportOnly = $opts.isPrioritySupportOnly;
				}
				
				commit('CLEAR_PRODUCTS_ARRAY');

				// subtotal
				let calcSubTotal = 0;

				if(_isPrioritySupportOnly){
					// purchasing priority support only
					// i need get "prorated" amount that goes in for this specific transaction

					if(rootState.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
						commit('PUSH_TO_PRODUCTS_ARRAY', {
							"productID": 204,
							"name": "PERRLA Priority Support Annual Subscription",
							"price": state.proRatedPrioritySupportPrice
						});	

					} else if(rootState.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						commit('PUSH_TO_PRODUCTS_ARRAY', {
							"productID": 206,
							"name": "PERRLA Priority Support Semi-Annual Subscription",
							"price": state.proRatedPrioritySupportPrice
						});	

					} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.TWO_YEARS){
						if(state.prioritySupport){
							commit('PUSH_TO_PRODUCTS_ARRAY', {
								"productID": 207,
								"name": "PERRLA Priority Support Two Year Subscription",
								"price": state.prices.proRatedPrioritySupportPrice
							});	
						}
					}
					
					calcSubTotal = state.proRatedPrioritySupportPrice;
					
					commit('SET_DISCOUNT', 0);
					commit('SET_SUBTOTAL', calcSubTotal);
					commit('SET_SUBTOTAL_DISPLAY', calcSubTotal);

					// tax
					let calcTaxApplied = 0;
					if(state.chargeTax){
						calcTaxApplied = Math.round((taxRateTN * calcSubTotal) * 100) / 100;
					}
	
					commit('SET_TAX', calcTaxApplied);
					
					// total
					commit('SET_GRAND_TOTAL', Math.round((calcSubTotal + calcTaxApplied) * 100) / 100);

				} else {
					if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
						commit('PUSH_TO_PRODUCTS_ARRAY', {
							"productID": 801,
							"name": "PERRLA Annual Subscription",
							"price": state.annualSubscriptionPrice
						});
						calcSubTotal += state.annualSubscriptionPrice;
	
						if(state.prioritySupport){
							commit('PUSH_TO_PRODUCTS_ARRAY', {
								"productID": 204,
								"name": "PERRLA Priority Support Annual Subscription",
								"price": state.prices.prioritySupportAnnualSubscription
							});	
							calcSubTotal += state.prices.prioritySupportAnnualSubscription;
						}
						
					} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						commit('PUSH_TO_PRODUCTS_ARRAY', {
							"productID": 803,
							"name": "PERRLA Semi-Annual Subscription",
							"price": state.prices.semiAnnualSubscription
						});
						calcSubTotal += state.prices.semiAnnualSubscription;
	
						if(state.prioritySupport){
							commit('PUSH_TO_PRODUCTS_ARRAY', {
								"productID": 206,
								"name": "PERRLA Priority Support Semi-Annual Subscription",
								"price": state.prices.prioritySupportSemiAnnualSubscription
							});	
							calcSubTotal += state.prices.prioritySupportSemiAnnualSubscription;
						}

					} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.TWO_YEARS){
						commit('PUSH_TO_PRODUCTS_ARRAY', {
							"productID": 804,
							"name": "PERRLA Two Year Subscription",
							"price": state.prices.twoYearSubscription
						});
						calcSubTotal += state.prices.twoYearSubscription;
	
						if(state.prioritySupport){
							commit('PUSH_TO_PRODUCTS_ARRAY', {
								"productID": 207,
								"name": "PERRLA Priority Support Two Year Subscription",
								"price": state.prices.prioritySupportTwoYearSubscription
							});	
							calcSubTotal += state.prices.prioritySupportTwoYearSubscription;
						}
					}

					// check 
					commit('SET_SUBTOTAL', calcSubTotal);
					
					let discountAmount = 0;
	
					// check for a promotionalCode object
					if(!_isEmpty(state.promotionalCode)){
						// calculate a promo code - just in case
						if(state.promotionalCode.codeType === 1 && state.promotionalCode.discountPercent !== 0){
							// apply a percent
							discountAmount = Math.round((state.promotionalCode.discountPercent / 100) * calcSubTotal * 1000) / 1000;

						} else if(state.promotionalCode.codeType === 3 && state.promotionalCode.dollarDiscount !== 0){
							// apply an amount
							if(state.promotionalCode.dollarDiscount !== 0){
								discountAmount = state.promotionalCode.dollarDiscount;
							}
						}
					}

					commit('SET_DISCOUNT', discountAmount);
					
					// remove any promo code discounts
					calcSubTotal = Math.round((calcSubTotal - discountAmount) * 100) / 100;
					
					// if(calcSubTotal === 0){
					// 	// the user doesn't owe any money (this is 100% discount)
					// 	commit('SET_RENEWAL_TYPE', config.enums.SubscriptionRenewalType.AUTO);
					// }

					commit('SET_SUBTOTAL_DISPLAY', calcSubTotal);
	
					// tax
					let calcTaxApplied = 0;
					if(state.chargeTax){
						calcTaxApplied = Math.round((taxRateTN * calcSubTotal) * 100) / 100;
					}
	
					commit('SET_TAX', calcTaxApplied);
					
					// total
					commit('SET_GRAND_TOTAL', Math.round((calcSubTotal + calcTaxApplied) * 100) / 100);
	
					let expirationDate;	

					if(state.isRenewal){
						// RENEWAL - set end date based on user's expiration date
						expirationDate = new Date(state.currentExpirationDate);	

						if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
							expirationDate = addMonths(expirationDate, 12);
						} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
							expirationDate = addMonths(expirationDate, 6);
						}
						
					} else {
						// NEW PURCHASE - set end date based on today
						expirationDate = new Date();	// start a new date object

						if(state.referringCustomerId === 0){
							if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
								expirationDate = addMonths(expirationDate, 12);
							} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
								expirationDate = addMonths(expirationDate, 6);
							}
						} else {
							// add three months
							if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
								expirationDate = addMonths(expirationDate, 15);
							} else if(state.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
								expirationDate = addMonths(expirationDate, 9);
							}
						}
					}
					
					let epirationDateOutput = dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');

					commit('SET_EXPIRATION_DATE_OUTPUT', epirationDateOutput);

				}
				
				resolve();
			});//e:promise
		},//e:updateCartTotals
	},
}