<!--
- Settings > AccountBilling
-
-->

<template>
	<nav-tab-content>
		<div class="row">
			<div class="col-12">
				<div class="mb-4">
					<h3>Subscription</h3>
					
					<div v-if="isSuscriptionExpired" class="mb-4" key="divExpired">
						<form-input-v2-display
							display-class="text-danger"
							label="Subscription"
							:is-full-width="true"
							:value="expirationDateOutput" />

						<form-row-slot :is-full-width="true">
							<button
								class="btn btn-success"
								type="button"
								@click.prevent="btnGoToSubscriptionSettings">
								Subscribe to PERRLA now
							</button>
						</form-row-slot>
						<br />
					</div><!--divExpired-->

					<div v-else key="divNotExpired">
						<form-input-v2-display
								label="Subscription"
								:is-full-width="true"
								:value="subscriptionOutput" />
							
							<div v-if="!isUserInFreeTrial">
								<form-input-v2-display
									label="Renewal"
									:is-full-width="true"
									:value="renewalOutput" />

								<form-row-slot
									style="margin-top: -1rem;"
									:is-full-width="true"
									v-if="!$store.state.customer.isRecurring">
									<a href="#" @click.prevent="btnChangeAccountToAutomaticRenewal">
										Change my account to renew automatically.
									</a>
								</form-row-slot>
							</div>
							
							<div v-if="$store.state.customer.isRecurring && Object.keys(paymentMethod).length === 0">
								<form-row-slot
									:label="expirationDateLabel"
									:is-full-width="true">

									<a href="#" @click.prevent="btnAddPaymentMethod">
										Add a payment method
									</a>&nbsp;<span v-html="expirationDateOutput" />
								</form-row-slot>
							</div>
							<form-input-v2-display
								v-else
								:is-full-width="true"	
								:label="expirationDateLabel"
								:value="expirationDateOutput" />

							<div v-if="isUserInFreeTrial" key="divUserInFreeTrial">	
								<form-row-slot :is-full-width="true">
									<div class="row">
										<div class="col pe-1">
											<div class="d-grid">
												<button 
													class="btn btn-success"
													type="button"
													@click.prevent="btnGoToSubscriptionSettings">
													Subscribe to PERRLA
												</button>
											</div>
										</div>
										<div class="col ps-1">
											<div class="d-grid">
												<button 
													class="btn btn-outline-danger"
													type="button"
													@click.prevent="btnCancelSubscription">
													Cancel my Free Trial
												</button>
											</div>
										</div>
									</div>
								</form-row-slot>
							</div>
							<div v-else key="divUserNotInFreeTrial">
								<div v-if="$store.state.customer.hasPrioritySupport" key="divHasPrioritySupport">
									<form-input-v2-display
										label="Priority Support"
										value="Included - your questions skip to the front of our Support line"
										:is-full-width="true" />
								</div>
								<div v-else key="divAddPrioritySupport">
									<form-row-slot
										label="Priority Support"
										:is-full-width="true">
										<a href="#" @click.prevent="addPrioritySupport">
											Add Priority Support to my Subscription
										</a>
									</form-row-slot>
								</div>

								<form-row-slot :is-full-width="true">
									<div class="row" v-if="isCancelled" key="rowExtendIsCancelled">
										<div class="col-6 pe-1">
											<div class="d-grid">
												<button
													v-if="$store.state.customer.isRecurring"
													class="btn btn-outline-primary"
													key="buttonRestore"
													type="button"
													@click.prevent="btnRestoreSubscription">
													Restore My Subscription
												</button>
												<button
													v-else
													class="btn btn-outline-primary"
													key="buttonRenew"
													type="button"
													@click.prevent="btnGoToSubscriptionSettings">
													Renew your PERRLA Subscription
												</button>
											</div>
										</div>
									</div><!--rowExtendIsCancelled-->

									<div class="row" v-else key="rowExtendElse">
										<div class="col pe-1">
											<div class="d-grid">
												<button 
													class="btn btn-outline-primary "
													type="button"
													@click.prevent="btnGoToSubscriptionSettings">
													{{ subscriptionButtonText }}
												</button>
											</div>
										</div>
										<div class="col ps-1">
											<div class="d-grid">
												<button 
													class="btn btn-outline-danger"
													type="button"
													@click.prevent="btnCancelSubscription">
													Cancel my Subscription
												</button>
											</div>
										</div>
									</div><!--divExtendElse-->
								</form-row-slot>
							</div><!--divUserNotInFreeTrial-->
						</div>
					</div><!--divNotExpired-->

					<div class="mb-4">
						<h3>Payment Method</h3>

						<div v-if="(Object.keys(paymentMethod).length > 0)" key="divPaymentMethodIf">
							<div v-if="(paymentMethod.paymentTypeID === $enums.CartPaymentType.CARD)" key="divPaymentCard">
								<form-input-v2-display
									label="Card"
									:is-full-width="true"
									:value="paymentMethod.name" />

								<form-input-v2-display
									label="Exp. Date"
									:is-full-width="true"
									:value="calcExpDate" />

								<form-input-v2-display
									label="Zip Code"
									:is-full-width="true"
									:value="paymentMethod.zipCode" />
							</div>
							<div v-else-if="(paymentMethod.paymentTypeID === $enums.CartPaymentType.ECHECK)" key="divPaymentEcheck">
								<form-input-v2-display
									label="Account"
									:is-full-width="true"
									:value="paymentMethod.name" />
							</div>

							<form-row-slot :is-full-width="true">
								<div class="row">
									<div class="col pe-1">
										<div class="d-grid">
											<button 
												class="btn btn-outline-primary"
												type="button"
												@click.prevent="btnAddPaymentMethod">
												Change Payment Method
											</button>
										</div>
									</div>
									<div class="col ps-1">
										<div class="d-grid">
											<button 
												class="btn btn-outline-danger"
												type="button"
												@click.prevent="btnRemovePaymentMethod">
												Remove Payment Method
											</button>
										</div>
									</div>
								</div>
							</form-row-slot>
							
						</div>
						<div v-else key="divPaymentMethodElse">

							<div v-if="$store.state.customer.isRecurring" key="divIsRecurring">
								<form-row-slot>
									<button 
										class="btn btn-success px-5 float-start me-2"
										type="button"
										@click.prevent="btnAddPaymentMethod">
											Add Payment Method
									</button>
								</form-row-slot>
							</div>
							<div v-else key="divIsRecurringElse">
								<form-input-v2-display
									label=""
									value="No payment method" />
							</div>

						</div>
					</div>

			</div><!--col-->
		</div><!--row-->
		
		<modal-add-payment-method />

	</nav-tab-content>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import AS_SyncData from '@/services/app/syncData';
import config from '@/config';
import dateToLongString from '@/helpers/date-to-long-string';
import FormInputV2Display from '@/components/form/InputV2Display';
import FormRowSlot from '@/components/form/RowSlot';
import ModalAddPaymentMethod from '@/views/Settings/Modals/AddPaymentMethod';
import NavTabContent from '@/components/NavTabContent';
import Swal from 'sweetalert2';

export default {
	name: 'SettingsAccountBilling',
	data() {
		return {
			paymentMethod: {},	// fill in after the sync call
		}
	},
	computed:{
		calcExpDate(){
			if(!_isEmpty(this.paymentMethod)){
				return this.paymentMethod.expirationMonth + '/' + this.paymentMethod.expirationYear; 
			}
			return '';
		},
		expirationDateLabel(){
			let returnText = '';
			if(this.$store.state.customer.isRecurring && !this.$store.state.customer.isCancelled && !this.isSuscriptionExpired){
				returnText = 'Renewal Date'
			} else {
				returnText = 'Expiration Date';	
			}
			return returnText;
		},
		expirationDateOutput(){
			let returnText = '';
			
			if(this.$store.state.customer.expirationDate){
				if(this.isUserInFreeTrial){
					returnText = '<span class="text-danger">Your PERRLA will expire on ';

					returnText += '<span class="fw-semibold">' + dateToLongString({
						dateValue: this.$store.state.customer.expirationDate,
						forceYear: true,
						showTime: false,
					});
					returnText += '</span>';
				
				} else if(this.isSuscriptionExpired){
					returnText = '<span class="text-danger">Your PERRLA expired on ';

					returnText += '<span class="fw-semibold">' + dateToLongString({
						dateValue: this.$store.state.customer.expirationDate,
						forceYear: true,
						showTime: false,
					});
					returnText += '</span>';
				
				} else {
					if(this.$store.state.customer.isRecurring && Object.keys(this.paymentMethod).length === 0){
						returnText = 'to automatically renew on ';
						returnText += '<span class="fw-semibold">' + dateToLongString({
							dateValue: this.$store.state.customer.expirationDate,
							forceYear: true,
							showTime: false,
						});

						returnText +=  '</span>';
						returnText +=  ' so your PERRLA access isn\'t interrupted.'
					} else {

						returnText = 'Your PERRLA will '
						
						let useExpirationDate = this.$store.state.customer.expirationDate;
						
						if(this.isCancelled){
							// cancelled accounts will always expire
							returnText += 'expire on ';
						} else {
							if(this.$store.state.customer.isRecurring){
								returnText += 'renew on ';
								useExpirationDate.setDate((useExpirationDate.getDate() - 1));	// renew script runs at noon the day before
							} else {
								returnText += 'expire on ';
							}						
						}					

						returnText += '<span class="fw-semibold">' + dateToLongString({
							dateValue: useExpirationDate,
							forceYear: true,
							showTime: false,
						});
						returnText +=  '</span>';
					}
				}
				
			}
			return returnText;
		},
		isCancelled(){
			return this.$store.state.customer.isCancelled;
		},
		isUserInFreeTrial(){
			if(this.$store.state.customer.role === config.enums.Status.FREE_TRIAL){
				return true;
			}
			return false;
		},
		isSuscriptionExpired(){
			if(this.$store.state.customer.expirationDate && (this.$store.state.customer.expirationDate < config.today)){
				return true;
			}
			return false;
		},
		renewalOutput(){
			if(this.isCancelled){
				return '<span class="text-danger">Cancelled - your PERRLA will not be renewed.</span>';
			} else {
				if(this.$store.state.customer.isRecurring){
					return 'Automatically Renew';
				} else {
					return 'Manual Renewal <span class="fw-light">(your PERRLA will not be automatically renewed)</span>';
				}
			}
		},
		subscriptionButtonText(){
			// If a user is set to Automatic renewal, but has no payment method, they currently still see the Automatic renewal buttons.
			// Since they cannot automatically renew, if they don't have a payment method show the Manual Renewal buttons (Extend my PERRLA Subscription Now & Cancel my Subscription).
			if(this.$store.state.customer.isRecurring && Object.keys(this.paymentMethod).length > 0){
				// automatic and has payment method
				return 'Change my Subscription Settings';
			} 
			// manual (assumed else)
			return 'Extend my PERRLA Subscription Now';
		},
		subscriptionOutput(){
			let returnText = '';

			if(this.isUserInFreeTrial){
				returnText = 'Free Trial';
			} else {
				returnText = '<span class="fw-semibold">';

				returnText += 'Active'
					
				returnText += '</span> - ';

				if(this.$store.state.customer.renewalPeriod){
					if(this.$store.state.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
						returnText += 'Annual Subscription';
					} else if(this.$store.state.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
						returnText += 'Semi-Annual Subscription';
					} else if(this.$store.state.customer.renewalPeriod === config.enums.SubscriptionRenewalPeriod.TWO_YEARS){
						returnText += 'Two Year Subscription';
					}
				}
			}
				
			return returnText;
		},
	},
	methods: {
		addPrioritySupport(){
			// this is only for adding priority support to an exisiting active subscription
			// it's only accessed from Settings > Account Billing
			this.$store.dispatch('cart/getNewCart').then(()=>{
				this.$router.push({
					name: 'PaymentSettingsPrioritySupport',
				}).catch(()=>{});
			});
		},//e:addPrioritySupport

		btnAddPaymentMethod(){
			this.$store.commit('modals/OPEN', 'AddPaymentMethod');
		},//e:btnAddPaymentMethod
		
		btnCancelSubscription(){
			Swal.fire({
				allowOutsideClick: false,
				buttonsStyling: false,
				title: 'Are you sure?',
				icon: 'question',
				cancelButtonText: 'Cancel My Subscription',
				confirmButtonText: 'Nevermind',
				showCloseButton: false,
				showCancelButton: true,
				showConfirmButton: true,
				customClass:{
					cancelButton: 'btn btn-danger',
					confirmButton: 'btn btn-outline-primary me-2',
				},
			}).then((result) => {
				if (!result.value) {
					this.$store.commit('loaders/ADD_ID', 'App');
					ApiS_MakeCall({
						method: 'DELETE',
						params: {
							customerId: this.$store.state.customer.id
						},
						responseType: 'none',
						url: 'v4/Customer/Subscription',
					}).then(()=>{
						window.location.reload();
					});//e:ApiS_MakeCall
					
				}
			});

		},//e:btnCancelSubscription

		btnChangeAccountToAutomaticRenewal(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				body: {
					"renewalPeriod": this.$store.state.customer.renewalPeriod,
					"renewalType": config.enums.SubscriptionRenewalType.AUTO,
					"prioritySupport": this.$store.state.customer.hasPrioritySupport,
				},
				method: 'PUT',
				responseType: 'text',
				url: 'v4/Customer/Settings/Subscription',
			}).then(()=>{
				window.location.reload();
			});//e:ApiS_MakeCall

		},//e:btnChangeAccountToAutomaticRenewal

		btnGoToSubscriptionSettings(){
			this.$store.dispatch('cart/getNewCart').then(()=>{
				this.$router.push({
					name: 'SubscriptionSettings',
				}).catch(()=>{});
			});
		},//e:btnGoToSubscriptionSettings

		btnRemovePaymentMethod(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				method: 'DELETE',
				params: {
					customerId: this.$store.state.customer.id
				},
				responseType: 'none',
				url: 'v4/PaymentMethod',
			}).then(()=>{
				this.paymentMethod = {};
				
				this.$store.commit('loaders/REMOVE_ID', 'App');

				this.emitter.emit('globalToasterOpen',{
					textContent: 'Payment method has been removed',
					variant: 'danger'
				});
			});//e:ApiS_MakeCall
		},//e:btnRemovePaymentMethod

		btnRestoreSubscription(){
			this.$store.commit('loaders/ADD_ID', 'App');

			ApiS_MakeCall({
				method: 'PUT',
				params: {
					customerId: this.$store.state.customer.id
				},
				responseType: 'none',
				url: 'v4/Customer/Subscription',
			}).then(()=>{
				// this.$router.go();
				window.location.reload();
			});//e:ApiS_MakeCall
		},//e:btnRestoreSubscription

	},
	mounted() {
		AS_SyncData().then(()=>{
			// is this customer in free trial?
			if(this.$store.state.customer.role === config.enums.Status.FREE_TRIAL){
				this.$router.push({
					name: 'MyProfile',
				}).catch(()=>{});
				
				this.$store.commit('loaders/REMOVE_ID', 'App');

			} else {
				// is this expired?
				if(this.isSuscriptionExpired){
					this.$router.push({
						name: 'MyProfile'
					}).catch(()=>{});

				} else{
					// look up this customers payment method
					ApiS_MakeCall({
						method: 'GET',
						responseType: 'json',
						url: 'v4/PaymentMethod',
					}).then((responseData)=>{
						// console.log('responseData');
						// console.log(responseData);
						
						this.paymentMethod = _cloneDeep(responseData);
						this.$store.commit('loaders/REMOVE_ID', 'App');

					}).catch(()=>{
						this.paymentMethod = {};
						this.$store.commit('loaders/REMOVE_ID', 'App');
					});//e:ApiS_MakeCall
				} //e:if:else
			}//e:if
		});//e:AS_SyncData
	},
	beforeRouteLeave (to, from, next) {
		if(to.name !== 'MyProfile' && to.name !== 'PerrlaOnline'){
			// before you leave this route, make sure the sidebar turns back on
			this.$store.commit('app/SET_IS_SIDEBAR_SHOWN', true);
		}
	
		next();
	},
	components: {
		FormInputV2Display,
		FormRowSlot,
		ModalAddPaymentMethod,
		NavTabContent,
	}
}
</script>
