var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav-tab-content",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-8" },
          [
            _c("h3", [_vm._v("Theme")]),
            _vm._v(" "),
            _c("form-row-slot", { attrs: { label: "Choose a theme" } }, [
              _c("div", { staticClass: "row mb-3" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.themeId,
                          expression: "themeId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_Theme_Light",
                        name: "PO_Theme",
                        type: "radio",
                      },
                      domProps: {
                        value: _vm.$enums.Theme.REGULAR,
                        checked: _vm._q(_vm.themeId, _vm.$enums.Theme.REGULAR),
                      },
                      on: {
                        change: function ($event) {
                          _vm.themeId = _vm.$enums.Theme.REGULAR
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_Theme_Light" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tLight\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.themeId,
                          expression: "themeId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_Theme_Dark",
                        name: "PO_Theme",
                        type: "radio",
                      },
                      domProps: {
                        value: _vm.$enums.Theme.DARK,
                        checked: _vm._q(_vm.themeId, _vm.$enums.Theme.DARK),
                      },
                      on: {
                        change: function ($event) {
                          _vm.themeId = _vm.$enums.Theme.DARK
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_Theme_Dark" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tDark\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.themeId,
                          expression: "themeId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_Theme_Pink",
                        name: "PO_Theme",
                        type: "radio",
                      },
                      domProps: {
                        value: _vm.$enums.Theme.SERRENA,
                        checked: _vm._q(_vm.themeId, _vm.$enums.Theme.SERRENA),
                      },
                      on: {
                        change: function ($event) {
                          _vm.themeId = _vm.$enums.Theme.SERRENA
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_Theme_Pink" },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tSerena (Pink)\n\t\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Calendar")]),
            _vm._v(" "),
            _c("form-row-slot", { attrs: { label: "Time Zone" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.timeZone,
                          expression: "timeZone",
                        },
                      ],
                      staticClass: "form-select",
                      attrs: { "aria-label": "Role" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.timeZone = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tEastern (-5 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 2 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tCentral (-6 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 3 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tMountain Arizona (-7 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 4 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tMountain (-7 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 5 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tPacific (-8 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 6 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tAlaska (-9 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 7 } }, [
                        _vm._v(
                          "\n\t\t\t\t\t\t\t\tHawaii (-10 GMT)\n\t\t\t\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("form-row-slot", { attrs: { label: "Starting Hour" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarStartingHour,
                          expression: "calendarStartingHour",
                        },
                      ],
                      staticClass: "form-select",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.calendarStartingHour = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                      },
                    },
                    [
                      _c("option", { domProps: { value: 1 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t1am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 2 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t2am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 3 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t3am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 4 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t4am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 5 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t5am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 6 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t6am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 7 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t7am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 8 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t8am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 9 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t9am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 10 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t10am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 11 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t11am\n\t\t\t\t\t\t\t"),
                      ]),
                      _vm._v(" "),
                      _c("option", { domProps: { value: 12 } }, [
                        _vm._v("\n\t\t\t\t\t\t\t\t12pm\n\t\t\t\t\t\t\t"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("form-row-slot", { attrs: { label: "Start Week On" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarStartingDayOfWeek,
                          expression: "calendarStartingDayOfWeek",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_StartWeekOn_Sunday",
                        name: "PO_StartWeekOn",
                        type: "radio",
                      },
                      domProps: {
                        value: 0,
                        checked: _vm._q(_vm.calendarStartingDayOfWeek, 0),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarStartingDayOfWeek = 0
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_StartWeekOn_Sunday" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tSunday\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarStartingDayOfWeek,
                          expression: "calendarStartingDayOfWeek",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_StartWeekOn_Monday",
                        name: "PO_StartWeekOn",
                        type: "radio",
                      },
                      domProps: {
                        value: 1,
                        checked: _vm._q(_vm.calendarStartingDayOfWeek, 1),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarStartingDayOfWeek = 1
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_StartWeekOn_Monday" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tMonday\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("form-row-slot", { attrs: { label: "Default View" } }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarViewId,
                          expression: "calendarViewId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_DefaultView_Month",
                        name: "PO_DefaultView",
                        type: "radio",
                      },
                      domProps: {
                        value: 1,
                        checked: _vm._q(_vm.calendarViewId, 1),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarViewId = 1
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_DefaultView_Month" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tMonth\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarViewId,
                          expression: "calendarViewId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_DefaultView_Week",
                        name: "PO_DefaultView",
                        type: "radio",
                      },
                      domProps: {
                        value: 2,
                        checked: _vm._q(_vm.calendarViewId, 2),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarViewId = 2
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_DefaultView_Week" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tWeek\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarViewId,
                          expression: "calendarViewId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_DefaultView_Day",
                        name: "PO_DefaultView",
                        type: "radio",
                      },
                      domProps: {
                        value: 3,
                        checked: _vm._q(_vm.calendarViewId, 3),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarViewId = 3
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_DefaultView_Day" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tDay\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "form-check form-check-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calendarViewId,
                          expression: "calendarViewId",
                        },
                      ],
                      staticClass: "form-check-input",
                      attrs: {
                        id: "PO_DefaultView_Agenda",
                        name: "PO_DefaultView",
                        type: "radio",
                      },
                      domProps: {
                        value: 4,
                        checked: _vm._q(_vm.calendarViewId, 4),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calendarViewId = 4
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "form-check-label",
                        attrs: { for: "PO_DefaultView_Agenda" },
                      },
                      [_vm._v("\n\t\t\t\t\t\t\t\tAgenda\n\t\t\t\t\t\t\t")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Papers")]),
            _vm._v(" "),
            _c(
              "form-row-slot",
              { attrs: { label: "Trigger Citation Create with Parentheses" } },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.citationAutoInsert,
                            expression: "citationAutoInsert",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "PO_TriggerCitationCreate_ENABLE",
                          name: "PO_TriggerCitationCreate",
                          type: "radio",
                        },
                        domProps: {
                          value: true,
                          checked: _vm._q(_vm.citationAutoInsert, true),
                        },
                        on: {
                          change: function ($event) {
                            _vm.citationAutoInsert = true
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "PO_TriggerCitationCreate_ENABLE" },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\t\tEnable\n\t\t\t\t\t\t\t")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-check form-check-inline" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.citationAutoInsert,
                            expression: "citationAutoInsert",
                          },
                        ],
                        staticClass: "form-check-input",
                        attrs: {
                          id: "PO_TriggerCitationCreate_DISABLE",
                          name: "PO_TriggerCitationCreate",
                          type: "radio",
                        },
                        domProps: {
                          value: false,
                          checked: _vm._q(_vm.citationAutoInsert, false),
                        },
                        on: {
                          change: function ($event) {
                            _vm.citationAutoInsert = false
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "form-check-label",
                          attrs: { for: "PO_TriggerCitationCreate_DISABLE" },
                        },
                        [_vm._v("\n\t\t\t\t\t\t\t\tDisable\n\t\t\t\t\t\t\t")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("p", [
                      _vm._v(
                        "Create citations in the Editor by typing an opening parenthesis."
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("h3", [_vm._v("References")]),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-outline-primary mb-2",
                attrs: { to: { name: "ReferencesSharedWithYou" } },
              },
              [_vm._v("\n\t\t\t\tView Shared Reference Invitations\n\t\t\t")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v(
                "\n\t\t\t\tView all shared reference invites from other users. You can also add declined reference shares to your Reference Library here.\n\t\t\t"
              ),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-outline-danger mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.btnArchiveReferences.apply(null, arguments)
                  },
                },
              },
              [_vm._v("\n\t\t\t\tArchive All References\n\t\t\t")]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-muted" }, [
              _vm._v(
                "\n\t\t\t\tThis will archive every reference in your PERRLA Library. Your existing papers will not be affected. Archived references and their Research Notes are NOT available to use in future papers. We do not recommend doing this without serious consideration.\n\t\t\t"
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("modal-archive-all-references"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }