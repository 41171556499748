<!--
- Generic Form Label and Password Field
-->

<template>
	<div :class="['form-group', {'row': isHorizontal}]">
		<div :class="{'col-sm-3': isHorizontal}">
			<label
				:class="{'fst-italic': isInvalidShown}"
				:for="safeId"
				v-html="label" />
		</div>

		<div :class="{'col-sm-9': isHorizontal}">
			<div class="help-text mb-1" v-if="helpText" v-html="helpText" />

			<div class="input-group">
				<input
					autocomplete="off"
					:class="['form-control border-end-0', {
						'is-invalid': isInvalidShown,
						'form-control-warning': isShowSuggestionCapsLock,
						}]"
					ref="focusElement"
					:id="safeId"
					:placeholder="placeholder"
					:type="localType"
					:value="value"
					@keydown="checkCapsLock"
					@blur="blurInput($event.target.value)"
					@input="typeInput($event.target.value)" /> 

				<span :class="['input-group-text input-group-text-password', {
					'form-control-warning': isShowSuggestionCapsLock,
				}]">
					<span :class="['svg svg-toggle-password', {'bi-eye': !isPasswordVisible, 'bi-eye-slash': isPasswordVisible}]" @click.prevent="togglePassword" />
				</span>
			</div>

			<div class="invalid-feedback d-block mt-1" v-if="isInvalidShown">
				{{ invalidErrorMessage }}
			</div>

			<div class="suggested-feedback" v-if="isShowSuggestionCapsLock" key="suggestedFeedbackCapsLockOn">
				Caps Lock is ON
			</div>
			
		</div><!--col-sm-9-->
	</div>
</template>

<script>
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import AS_ValidateForm from '@/services/app/validateForm';
import mixinId from '@/mixins/id';

export default {
	name: 'FormPassword',
	mixins: [mixinId],
	props:{
		formId: {
			type: String,
		},
		isHorizontal:{	// contact support is a horizontal form but the default wil be false for all other form inputs
			default: false,
			type: Boolean,
		},
		helpText: {
			type: String,
		},
		label: {
			type: String,
		},
		placeholder:{
			default: '',
			type: String
		},
		type:{
			default: 'password',
			type: String
		},
		validateEnum: {
			default: 0,
			type: Number
		},
		value: {
			type: String,
		},
	},
	data(){
		return {
			isPasswordVisible: false,
			isShowSuggestionCapsLock: false,
			localType: null,
		}
	},
	computed:{
		invalidErrorMessage(){
			if(this.thisValidation){
				return this.thisValidation.invalidErrorMessage;
			}
			return '';
		},
		isInvalidShown(){
			if(this.thisValidation){
				return this.thisValidation.isShown;
			}
			return false;
		},
		thisValidation(){
			if(this.validateEnum !== 0 && !_isEmpty(this.$store.state.forms.validation)){
				// find this validation by form id
				let findValidation = this.$store.state.forms.validation.find((validationData)=>{
					return validationData.fullId === this.safeId
				});
				if(findValidation){
					return findValidation;
				} else {
					return null;
				}
			}
			return null;
		},
	},
	methods:{
		blurInput(value){
			AS_ValidateForm({
				singleFieldId: this.safeId
			}).then(()=>{
				this.$emit('blur-input', value);
			}).catch(()=>{});//e:AS_ValidateForm
		},//e:blurInput

		checkCapsLock(event){
			if(event.getModifierState("CapsLock")) {
				this.isShowSuggestionCapsLock = true;
			} else {
				this.isShowSuggestionCapsLock = false;
			}
		},
		
		togglePassword(){
			this.isPasswordVisible = !this.isPasswordVisible;
			
			if(this.isPasswordVisible){
				this.localType = 'text';
			} else {
				this.localType = 'password';
			}
		},

		typeInput(value){
			this.$store.commit('forms/MARK_FORM_DIRTY_BY_ID', {
				formId: this.formId,
			});

			this.$emit('input', value);
		},//e:typeInput
	},
	
	mounted(){
		// auto add this item to form validation object in the store
		if(this.validateEnum !== 0){
			this.$store.dispatch('forms/addValidation', {
				formId: this.formId,
				fieldLabel: this.label,
				fullId: this.safeId,
				validateEnum: this.validateEnum,
			})
		}

		this.localType = this.type;
	},
}
</script>
