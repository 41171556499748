var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "shared-reference-strip d-flex cursor-pointer mb-2",
      on: {
        click: function ($event) {
          $event.preventDefault()
          return _vm.openSharedRefModal.apply(null, arguments)
        },
      },
    },
    [
      _c("span", { staticClass: "svg bi-exclamation-lg float-start" }),
      _vm._v(" "),
      _c("p", { staticClass: "flex-fill" }, [
        _vm._v(
          "New references shared with you! Click here to add them to your Reference Library."
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "button" } },
        [_vm._v("\n\t\tAdd References to My Library\n\t")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }