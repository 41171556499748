<!--
- PERRLA Online Layout > Shared Refrence Strip
-->

<template>
	<div class="shared-reference-strip d-flex cursor-pointer mb-2" @click.prevent="openSharedRefModal">
		<span class="svg bi-exclamation-lg float-start" />
		<p class="flex-fill">New references shared with you! Click here to add them to your Reference Library.</p>
		<button type="button" class="btn btn-primary">
			Add References to My Library
		</button>
	</div>
</template>

<script>
export default {
	name: 'SharedRefrenceStrip',
	methods: {
		openSharedRefModal(){
			this.$store.commit('modals/OPEN', 'SharedReferencesReceived');
		}
	},
}
</script>

<style lang="scss" scoped>
	@import "../../assets/styles/_vars_perrla.scss";
	
	@include color-mode(dark) {
		.shared-reference-strip {
			background-color: $warning-900;
			color: $warning-300;
		
			&:hover{
				background-color: $warning-700;
				color: $warning-500;
			}
		}		
	}

	.shared-reference-strip {
		background-color: $warning-300;
		border-bottom: 2px solid $body-color;
		color: $warning-900;
		display: block;
		margin: -15px -12px 0 -12px;
		padding: 8px;

		&:hover{
			background-color: $warning-500;
			color: $body-color;
		}

		.svg {
			
			font-size: 24px;
			line-height: 40px;
		}

		p {
			font-size: 16px;
			line-height: 40px;
			margin-bottom: 0;
		}
	}
	
</style>