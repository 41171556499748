<!--
- Shared Reference Invitations > Row
-
-->

<template>
	<tr :class="calcRowClasses" @click.prevent="openInvitationAlert">
        <td v-html="calcInvitationTitle" class="td-entity-title td-entity-click" />
        <td class="table-spacer" />
        <td class="td-entity-click word-break">
            <!-- Offering journey truth eh Tom cream? Urgent splash threat prelude someone club tweens Emyn. Galadriel doorway stealing ambushed. Farmer immediately strayed dicky thinks deeper Frodo Baggins mattered. Steel chair Longshanks laddie splash gollum pighea -->
            {{ invitationData.message }}
        </td>
        <td class="table-spacer" />
        <td class="td-entity-click" v-html="calcInitationStatus" />
            
        
    </tr>
</template>

<script>
import config from '@/config';

export default {
	name: 'ReferencesSharedWithYouRow',
	props:{
        invitationData:{
			required: true,
			type: Object,
		},
	},
	computed:{
        calcInitationStatus(){
            if(this.invitationData.status === config.enums.RefShareInvitationStatus.ACCEPTED){
                return '<span class="text-success-700">Accepted</span>';
            } else if(this.invitationData.status === config.enums.RefShareInvitationStatus.DECLINED){
                return '<span class="text-danger-700">Declined</span>';
            } else if(this.invitationData.status === config.enums.RefShareInvitationStatus.PENDING){
                return '<span class="text-warning-700">Pending</span>';
            }

            return config.emptyText;
        },
        calcInvitationTitle(){
            let returnHtml = '';
            returnHtml += '<strong>';
                returnHtml += this.invitationData.references.length + ' references';
            returnHtml += '</strong> ';
            returnHtml += 'shared by ';
            returnHtml += '<strong>';
                returnHtml += this.invitationData.firstName;
            returnHtml += '</strong> ';

            return returnHtml;
        },
        calcRowClasses(){
            let returnArray = ['tr-entity-grid-row'];

            if(this.invitationData.status === config.enums.RefShareInvitationStatus.DECLINED){
                returnArray.push('tr-entity-grid-declined-reference-invitation');
            } else if(this.invitationData.status === config.enums.RefShareInvitationStatus.ACCEPTED){
                returnArray.push('tr-entity-grid-accepted-reference-invitation');
            } else if(this.invitationData.status === config.enums.RefShareInvitationStatus.PENDING){
                returnArray.push('tr-entity-grid-pending-reference-invitation');
            }

			return returnArray;
        },
        isClickableRow(){
            if(
                this.invitationData.status === config.enums.RefShareInvitationStatus.DECLINED ||
                this.invitationData.status === config.enums.RefShareInvitationStatus.PENDING
            ){
                return true;
            }
            return false;
        },
	},
	methods: {
        openInvitationAlert(){
            // console.log(this.invitationData);
            this.$store.commit('modals/SET_MODAL_DATA', this.invitationData);
            this.$store.commit('modals/OPEN', 'SharedReferencesReceivedArchived');
        },
	},
}
</script>

