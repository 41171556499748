<!--
- Archive All References
-->

<template>
	<generic-modal-wrap modal-id="ArchiveAllReferences">
		<h2 class="nodal-title">
			Are you really sure you want to do this?
		</h2>
        
        <p>
            This will archive all of the references in your PERRLA Library - it will be like starting over.
        </p>

        <p>
            Alternatively, you can archive select references (one at a time, or multiple at once) in your PERRLA Reference Library.
        </p>
    
        <form-checkbox
            label="YES - I WANT TO WIPE OUT MY REFERENCE LIBRARY COMPLETELY."
            class="mb-4"
            v-model="isConfirmChecked" />
        
        <div class="row">
            <div class="col-7 d-grid">
                <button
                    class="btn btn-outline-danger"
                    type="button"
                    @click.prevent="btnArchiveAllReferences"
                    :disabled="!isConfirmChecked">
                    Yes - Archive All References
                </button>
            </div>
            <div class="col-5 d-grid">
                <button
                    class="btn btn-primary"
                    type="button"
                    @click.prevent="$store.commit('modals/CLOSE', 'ArchiveAllReferences')">
                    Nevermind
                </button>
            </div>
        </div>


	</generic-modal-wrap>

</template>

<script lang="js">
import ApiS_MakeCall from '@/services/app/api/makeCall';
import FormCheckbox from '@/components/form/Checkbox';
import GenericModalWrap from '@/components/modals/GenericWrap.vue';

export default {
	name: 'ArchiveAllReferences',
    data() {
		return {
            isConfirmChecked: false,
		}
	},
    methods:{
        btnArchiveAllReferences(){
            if(this.isConfirmChecked){
                this.$store.commit('loaders/ADD_ID', 'App');

                ApiS_MakeCall({
                    method: 'DELETE',
                    url: 'v4/GenesisReference/Library',
                }).then(()=>{
                    this.$store.commit('REFERENCE_LIBRARY', []);
                    this.$store.commit('REFERENCE_LIBRARY_COMPRESSED_STRING', '');
                    this.$store.commit('REFERENCE_LIBRARY_GENESIS', []);
                    this.$store.commit('REFERENCE_LIBRARY_GENESIS_COMPRESSED_STRING', '');
                    this.$store.commit('LAST_SYNC_DATE', '');
                    
                    window.location.reload();   // this could come from Settings or Reference List, just refresh the page the user is on
                });//e:ApiS_MakeCall
            }//e:if
        },//e:btnArchiveAllReferences
    },
	components:{
        FormCheckbox,
		GenericModalWrap,
	},
}
</script>
