<!--
- SubscriptionSettings
-- Cart > Step 2
-->

<template>
	<div class="subscription-settings-wrp">
	
		<div class="boxset-wrp">
			<div class="alert alert-danger" v-if="!$isLive">
				<p class="fw-bold p-2">
					This is testing environment, to make a purchase, please visit: <a href="https://www.perrlacomplete.com/Public/#/cart-step-1">https://www.perrlacomplete.com/Public/#/cart-step-1</a>
				</p>
			</div>

			<h1>Subscription Settings</h1>

			<div class="row pt-3">
				<div class="col-12 col-md-6 col-divider-right mt-2">
					<h3 class="mt-0">
						Choose the following options:
					</h3>

					<h4>Subscription Length</h4>
					<p class="help-text ms-md-2">
						Choose how long you'd like to have access to PERRLA.
					</p>
				
					<div class="row mt-3">
						<div class="col ms-md-4">
							<div class="form-group">
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="renewalPeriod_TwoYears"
										name="renewalPeriod"
										:value="$enums.SubscriptionRenewalPeriod.TWO_YEARS"
										v-model="renewalPeriod" /> 
									<label class="form-check-label" for="renewalPeriod_TwoYears">2 years for {{ calcTwoYearsSubscriptionPrice }} <span class="fw-300" v-if="showSavingPercent">(save 35%)</span></label>
								</div>

								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="renewalPeriod_Annual"
										name="renewalPeriod"
										:value="$enums.SubscriptionRenewalPeriod.ANNUAL"
										v-model="renewalPeriod" /> 
									<label class="form-check-label" for="renewalPeriod_Annual">1 year for {{ calcAnnualSubscriptionPrice }} <span class="fw-300" v-if="showSavingPercent">(save 20%)</span></label>
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="renewalPeriod_Semi"
										name="renewalPeriod"
										:value="$enums.SubscriptionRenewalPeriod.SEMI"
										v-model="renewalPeriod" /> 
									<label class="form-check-label" for="renewalPeriod_Semi">6 months for {{ calcSemiAnnualSubscriptionPrice }}</label>
								</div>
							</div>
						</div>
					</div>

					<h4>Renewal Method</h4>
					<p class="help-text ms-md-2">
						Choose to renew your subscription yourself or to let PERRLA renew it automatically before it expires.
					</p>
					<div class="row mt-3">
						<div class="col ms-md-4">
							<div class="form-group">
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="renewalType_Auto"
										name="renewalType"
										:value="$enums.SubscriptionRenewalType.AUTO"
										v-model="renewalType" /> 
									<label class="form-check-label" for="renewalType_Auto" v-html="calcRenewAutomaticallyOutput" />
								</div>
								<div class="form-check">
									<input
										class="form-check-input"
										type="radio"
										id="renewalType_Manual"
										name="renewalType"
										:value="$enums.SubscriptionRenewalType.MANUAL"
										v-model="renewalType" /> 
									<label class="form-check-label" for="renewalType_Manual">Renew manually <span class="fw-300">(your account exipres unless you renew it)</span></label>
								</div>
							</div>
						</div>
					</div>

					<div v-if="isShowPrioritySupport">
						<h4>Priority Support</h4>
						<p class="help-text ms-md-2">
							- Expedited help from our Customer Support team.<br />
							- Your request automatically moves to the top of our Support queue.<br />
							- The fastest way to get our help when you need it.
						</p>
						<div class="row mt-4">
							<div class="col ms-md-4">
								<div class="form-group">
									<div class="form-check">
										<input
											class="form-check-input"
											type="radio"
											id="prioritySupport_false"
											name="prioritySupport"
											:value="false"
											v-model="prioritySupport" /> 
										<label class="form-check-label" for="prioritySupport_false">No thanks</label>
									</div>
									<div class="form-check">
										<input
											class="form-check-input"
											type="radio"
											id="prioritySupport_true"
											name="prioritySupport"
											:value="true"
											v-model="prioritySupport" /> 
										<label class="form-check-label" for="prioritySupport_true">Yes, include Priority Support - {{ calcPrioritySupportPrice }}</label>
									</div>
								</div>
							</div><!--col-->
						</div><!--row-->
					</div><!--v-if-isShowPrioritySupport-->
				</div><!--col-->
				<div class="col-12 col-md-6 mt-2 ps-3">
					<transaction-preview />
					<!-- <div class="alert alert-warning">
						<p>isPaymentMethodEmpty: {{ isPaymentMethodEmpty }}</p>
					</div> -->

					<div class="d-grid gap-2">
						<button 
							v-if="!isPaymentMethodEmpty && isRenewal && isRecurring && !isExpired && !isFreeTrial"
							class="btn btn-success"
							key="buttonUpdateSubscriptionSettings"
							type="button"
							@click.prevent="btnUpdateSubscriptionSettings">
							Update Subscription Settings
						</button>
						<button 
							v-else
							class="btn btn-success"
							key="buttonNexyPaymentInfo"
							type="button"
							@click.prevent="btnGoToPayment">
							Next: Payment Information
						</button>

						<button 
							v-if="!isExpired && !isFreeTrial"
							class="btn btn-outline-danger"
							type="button"
							@click.prevent="btnCancel">
							Cancel changes
						</button>
					</div><!--d-grid-->

				</div>
			</div>
		</div><!--boxset-wrp-->
	</div><!--subscription-settings-wrp-->
</template>

<script>
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import { addMonths, subMonths } from 'date-fns';
import ApiS_MakeCall from '@/services/app/api/makeCall';
import config from '@/config';
import currencyFormat from '@/helpers/currency';
import dateFormat from "dateformat";
import TransactionPreview from './TransactionPreview';

const BYPASS_CART_STEP_1 = (config.$isLive) ? false : false;

export default {
	name: 'SubscriptionSettings',
	data(){
		return {
			isPaymentMethodEmpty: true,
			isShowPrioritySupport: true,
		}
	},
	computed:{
		calcRenewAutomaticallyOutput(){
			let expirationDate;	

			if(this.isExpired){
				// EXPIRED - based on today
				expirationDate = new Date();
				
			} else {
				// ACTIVE - based on the current exp date
				expirationDate = new Date(this.$store.state.cart.currentExpirationDate);

			}

			// adust the date based on their selected period
			if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
				expirationDate = addMonths(expirationDate, 6);
			} else if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
				expirationDate = addMonths(expirationDate, 12);
			} else if(this.$store.state.cart.renewalPeriod === config.enums.SubscriptionRenewalPeriod.TWO_YEARS){
				expirationDate = addMonths(expirationDate, 24);
			}

			if(this.$store.state.customer.id == '0'){
				// this is a new customer that is purchasing for the first time
				return 'Renew automatically on ' + dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
			} else {
				// this is an exisiting customer, probably editing their subscription
				if(this.$store.state.customer.isRecurring){
					return 'Renew automatically on ' + dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
				} else {
					return 'Renew automatically <span class="fw-300">(your account will be renewed today and set to automatically renew on ' + dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy') + ')</span>';
				}
			}
			
		},//e:calcExpirationDateRenewAutomaticallyOutput

		calcExtendToDateOutput6Months(){
			let expirationDate;	
			if(this.isExpired){
				// EXPIRED - based on today
				expirationDate = new Date();
			} else {
				// ACTIVE - based on the current exp date
				expirationDate = new Date(this.$store.state.cart.currentExpirationDate);
			}

			expirationDate = addMonths(expirationDate, 6);
			
			return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
			
		},//e:calcExtendToDateOutput6Months

		calcExtendToDateOutput12Months(){
			let expirationDate;	
			if(this.isExpired){
				// EXPIRED - based on today
				expirationDate = new Date();
			} else {
				// ACTIVE - based on the current exp date
				expirationDate = new Date(this.$store.state.cart.currentExpirationDate);
			}

			expirationDate = addMonths(expirationDate, 12);

			return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
		},//e:calcExtendToDateOutput12Months
		
		calcExtendToDateOutputTwoYears(){
			let expirationDate;	
			if(this.isExpired){
				// EXPIRED - based on today
				expirationDate = new Date();
			} else {
				// ACTIVE - based on the current exp date
				expirationDate = new Date(this.$store.state.cart.currentExpirationDate);
			}

			expirationDate = addMonths(expirationDate, 24);

			return dateFormat(expirationDate, 'mmm') + '. ' + dateFormat(expirationDate, 'd') + ', ' + dateFormat(expirationDate, 'yyyy');
		},//e:calcExtendToDateOutputTwoYears

		calcAnnualSubscriptionPrice(){
			if(_has(this.$store.state.cart, 'annualSubscriptionPrice')){
				return currencyFormat({
					value: this.$store.state.cart.annualSubscriptionPrice
				});
			}
			return '';
		},
		calcPrioritySupportPrice(){
			if(this.renewalPeriod === config.enums.SubscriptionRenewalPeriod.ANNUAL){
				if(_has(this.$store.state.cart.prices, 'prioritySupportAnnualSubscription')){
					return currencyFormat({
						value: this.$store.state.cart.prices.prioritySupportAnnualSubscription
					});
				}
			} else if(this.renewalPeriod === config.enums.SubscriptionRenewalPeriod.SEMI){
				if(_has(this.$store.state.cart.prices, 'prioritySupportSemiAnnualSubscription')){
					return currencyFormat({
						value: this.$store.state.cart.prices.prioritySupportSemiAnnualSubscription
					});
				}
			} else if(this.renewalPeriod === config.enums.SubscriptionRenewalPeriod.TWO_YEARS){
				if(_has(this.$store.state.cart.prices, 'prioritySupportTwoYearSubscription')){
					return currencyFormat({
						value: this.$store.state.cart.prices.prioritySupportTwoYearSubscription
					});
				}
			}

			return '';
		},
		calcSemiAnnualSubscriptionPrice(){
			if(_has(this.$store.state.cart.prices, 'semiAnnualSubscription')){
				return currencyFormat({
					value: this.$store.state.cart.prices.semiAnnualSubscription
				});
			}
			return '';
		},
		calcTwoYearsSubscriptionPrice(){
			if(_has(this.$store.state.cart.prices, 'twoYearSubscription')){
				return currencyFormat({
					value: this.$store.state.cart.prices.twoYearSubscription
				});
			}
		},
		isExpired(){
			if(this.$store.state.customer.expirationDate > config.today){
				return false;
			}
			return true;
		},
		isFreeTrial(){
			if(this.$store.state.customer.role === config.enums.Status.FREE_TRIAL){
				return true;
			}
			return false;
		},
		isRecurring(){
			return this.$store.state.customer.isRecurring;
		},
		isRenewal(){
			return this.$store.state.cart.isRenewal;
		},
		isEditingSubscriptionSettigs(){
			if(this.isRenewal && this.isRecurring){
				return true;
			}
			return false;
		},
		prioritySupport:{
			get(){
				return this.$store.state.cart.prioritySupport;
			},
			set(newValue){
				this.$store.commit('cart/SET_PRIORITY_SUPPORT', newValue);
				this.$store.dispatch('cart/updateCartTotals');
			}
		},
		renewalPeriod:{
			get(){
				return this.$store.state.cart.renewalPeriod;
			},
			set(newValue){
				this.$store.commit('cart/SET_RENEWAL_PERIOD', newValue);
				this.$store.dispatch('cart/updateCartTotals');
			}
		},
		renewalType:{
			get(){
				return this.$store.state.cart.renewalType;
			},
			set(newValue){
				this.$store.commit('cart/SET_RENEWAL_TYPE', newValue);
				this.$store.dispatch('cart/updateCartTotals');
			}
		},
		showSavingPercent(){
			if(_has(this.$store.state.cart, 'annualSubscriptionPrice') && _has(this.$store.state.cart.prices, 'semiAnnualSubscription') && _has(this.$store.state.cart.prices, 'twoYearSubscription')){
				// i have all the raw cart values, now compare them
				if(this.$store.state.cart.prices.semiAnnualSubscription < this.$store.state.cart.annualSubscriptionPrice){
					return true;
				}
			}
			return false;
		},
		
	},
	methods:{
		btnCancel(){
			// this.$router.go(-1);
			if(this.$store.state.api.token === ''){
				// not logged in - ID User
				this.$router.push({
					name: 'IdentifyUser'
				}).catch(()=>{});
			} else {
				// logged in
				if(this.$store.state.customer.role === config.enums.Status.FREE_TRIAL){
					// Free Trial - back to Settigns > Free Trial
					this.$router.push({
						name: 'MyProfile'
					}).catch(()=>{});
				} else {
					// Full Access - back to Settigns > Account & Billing Tab
					this.$router.push({
						name: 'AccountBilling'
					}).catch(()=>{});
				}
				
			}
		},//btnCancel

		btnGoToPayment(){
			this.$store.commit('loaders/ADD_ID', 'App');

			// simulate making an api call
			setTimeout(()=>{
				this.$router.push({
					name: 'PaymentSettings',
				}).catch(()=>{});
			}, 66);
		},

		btnUpdateSubscriptionSettings(){
			this.$store.commit('loaders/ADD_ID', 'App');

			let sendBody = {
				"renewalPeriod": this.renewalPeriod,
				"renewalType": this.renewalType,
				"prioritySupport": this.prioritySupport,
			}
			
			ApiS_MakeCall({
				body: sendBody,
				method: 'PUT',
				responseType: 'text',
				url: 'v4/Customer/Settings/Subscription',
			}).then(()=>{
				this.$router.push({
					name: 'MyProfile'
				}).catch(()=>{});
			});//e:ApiS_MakeCall

		},//e:btnUpdateSubscriptionSettings
	},
	mounted() {

		if(config.isLive || !BYPASS_CART_STEP_1){
			// live site - keep the normal cart functions

			if(this.$store.state.cart.shoppingCartUserId === 0) {
				// there isn't a valid cart ide so go back to step 1
				this.$router.push({
					name: 'IdentifyUser'
				}).catch(()=>{});

			} else {
				// there is a valid cart id
				this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);
				
				// force remove any theme classes - this view will not have a customer theme
				document.documentElement.setAttribute('data-bs-theme', 'light')
				document.body.classList.remove('theme-serrena');
				document.body.classList.add('bg-simple');

				this.$store.dispatch('cart/updateCartTotals').then(()=>{
					
					if(this.isExpired){
						this.isShowPrioritySupport = true;

					} else {
						if(this.isRenewal && !this.isFreeTrial){
							if(this.isRecurring){
								this.isShowPrioritySupport = this.$store.state.cart.prioritySupport;
							} else {
								this.isShowPrioritySupport = true;	
							}
						} else {
							this.isShowPrioritySupport = true;
						}
					}

					if(this.$store.state.customer.id == '0'){
						// this is a new customer that is purchasing for the first time, don't check their payment method
						this.isPaymentMethodEmpty = true;
						this.$store.commit('loaders/REMOVE_ID', 'App');

					} else {
						// this is an exisiting customer, probably editing their subscription
						ApiS_MakeCall({
							method: 'GET',
							responseType: 'json',
							url: 'v4/PaymentMethod',
						}).then((responseData)=>{
							if(_isEmpty(responseData)){
								this.isPaymentMethodEmpty = true;
							} else {
								this.isPaymentMethodEmpty = false;
							}

							this.$store.commit('loaders/REMOVE_ID', 'App');

						}).catch(()=>{
							this.$store.commit('loaders/REMOVE_ID', 'App');
						});//e:ApiS_MakeCall
					}//e:if:else:id
					
				});//e:dispatch.cart/updateCartTotals

			}//e:if:shoppingCartUserId

		} else {
			// demo while renavating the pricing items (don't redirect me - change BYPASS_CART_STEP_1 to false)

			// fake getting a new cart
			this.$store.dispatch('cart/getNewCart', {
				'shoppingCartUserID': 333333
			}).then(()=>{

				// force remove any theme classes - this view will not have a customer theme
				document.documentElement.setAttribute('data-bs-theme', 'light')
				document.body.classList.remove('theme-serrena');
				document.body.classList.add('bg-simple');

				this.$store.commit('app/SET_LAYOUT_ENUM', config.enums.Layout.CART);

				this.$store.dispatch('cart/updateCartTotals').then(()=>{
					console.log('cartUpdateTotals are done - you should be on the test server now');

				});//e:dispatch.cart/updateCartTotals
				
			});//e:dispatch.cart/getNewCart

		}//e:if:else:isLive
	},
	components:{
		TransactionPreview
	},
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/vars_perrla";

.subscription-settings-wrp{
	margin-top: 5.5rem;

	@include media-breakpoint-down(md) { 
		margin-top: 2.5rem;
		margin-bottom: 1.5rem;
	}
}
</style>