var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          ref: "divReferenceListWrp",
          staticClass: "col-12 min-height overflow-y-auto position-relative",
        },
        [
          _vm.arrayOfInvitations.length > 0
            ? _c(
                "table",
                { staticClass: "table table-borderless entity-grid" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.arrayOfInvitations,
                      function (invitationData, index) {
                        return _c("invitation-row", {
                          key: index,
                          attrs: { "invitation-data": invitationData },
                        })
                      }
                    ),
                    1
                  ),
                ]
              )
            : _c("div", { staticClass: "text-muted text-center mt-5" }, [
                _c("p", [_vm._v("No invitations found.")]),
              ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex flex-row mb-2" }, [
      _c("h1", { staticClass: "float-start me-3 mb-0" }, [
        _vm._v("\n\t\t\tReferences Shared with You\n\t\t"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Shared References")]),
        _vm._v(" "),
        _c("th", { staticClass: "table-spacer" }),
        _vm._v(" "),
        _c("th", [_vm._v("Message")]),
        _vm._v(" "),
        _c("th", { staticClass: "table-spacer" }),
        _vm._v(" "),
        _c("th", { attrs: { width: "100px" } }, [_vm._v("Status")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }