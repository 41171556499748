var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "subscription-settings-wrp" }, [
    _c("div", { staticClass: "boxset-wrp" }, [
      !_vm.$isLive
        ? _c("div", { staticClass: "alert alert-danger" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c("h1", [_vm._v("Subscription Settings")]),
      _vm._v(" "),
      _c("div", { staticClass: "row pt-3" }, [
        _c("div", { staticClass: "col-12 col-md-6 col-divider-right mt-2" }, [
          _c("h3", { staticClass: "mt-0" }, [
            _vm._v("\n\t\t\t\t\tChoose the following options:\n\t\t\t\t"),
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Subscription Length")]),
          _vm._v(" "),
          _c("p", { staticClass: "help-text ms-md-2" }, [
            _vm._v(
              "\n\t\t\t\t\tChoose how long you'd like to have access to PERRLA.\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col ms-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renewalPeriod,
                        expression: "renewalPeriod",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "radio",
                      id: "renewalPeriod_TwoYears",
                      name: "renewalPeriod",
                    },
                    domProps: {
                      value: _vm.$enums.SubscriptionRenewalPeriod.TWO_YEARS,
                      checked: _vm._q(
                        _vm.renewalPeriod,
                        _vm.$enums.SubscriptionRenewalPeriod.TWO_YEARS
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.renewalPeriod =
                          _vm.$enums.SubscriptionRenewalPeriod.TWO_YEARS
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "renewalPeriod_TwoYears" },
                    },
                    [
                      _vm._v(
                        "2 years for " +
                          _vm._s(_vm.calcTwoYearsSubscriptionPrice) +
                          " "
                      ),
                      _vm.showSavingPercent
                        ? _c("span", { staticClass: "fw-300" }, [
                            _vm._v("(save 35%)"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renewalPeriod,
                        expression: "renewalPeriod",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "radio",
                      id: "renewalPeriod_Annual",
                      name: "renewalPeriod",
                    },
                    domProps: {
                      value: _vm.$enums.SubscriptionRenewalPeriod.ANNUAL,
                      checked: _vm._q(
                        _vm.renewalPeriod,
                        _vm.$enums.SubscriptionRenewalPeriod.ANNUAL
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.renewalPeriod =
                          _vm.$enums.SubscriptionRenewalPeriod.ANNUAL
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "renewalPeriod_Annual" },
                    },
                    [
                      _vm._v(
                        "1 year for " +
                          _vm._s(_vm.calcAnnualSubscriptionPrice) +
                          " "
                      ),
                      _vm.showSavingPercent
                        ? _c("span", { staticClass: "fw-300" }, [
                            _vm._v("(save 20%)"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renewalPeriod,
                        expression: "renewalPeriod",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "radio",
                      id: "renewalPeriod_Semi",
                      name: "renewalPeriod",
                    },
                    domProps: {
                      value: _vm.$enums.SubscriptionRenewalPeriod.SEMI,
                      checked: _vm._q(
                        _vm.renewalPeriod,
                        _vm.$enums.SubscriptionRenewalPeriod.SEMI
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.renewalPeriod =
                          _vm.$enums.SubscriptionRenewalPeriod.SEMI
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "renewalPeriod_Semi" },
                    },
                    [
                      _vm._v(
                        "6 months for " +
                          _vm._s(_vm.calcSemiAnnualSubscriptionPrice)
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Renewal Method")]),
          _vm._v(" "),
          _c("p", { staticClass: "help-text ms-md-2" }, [
            _vm._v(
              "\n\t\t\t\t\tChoose to renew your subscription yourself or to let PERRLA renew it automatically before it expires.\n\t\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col ms-md-4" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renewalType,
                        expression: "renewalType",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "radio",
                      id: "renewalType_Auto",
                      name: "renewalType",
                    },
                    domProps: {
                      value: _vm.$enums.SubscriptionRenewalType.AUTO,
                      checked: _vm._q(
                        _vm.renewalType,
                        _vm.$enums.SubscriptionRenewalType.AUTO
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.renewalType =
                          _vm.$enums.SubscriptionRenewalType.AUTO
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("label", {
                    staticClass: "form-check-label",
                    attrs: { for: "renewalType_Auto" },
                    domProps: {
                      innerHTML: _vm._s(_vm.calcRenewAutomaticallyOutput),
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.renewalType,
                        expression: "renewalType",
                      },
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "radio",
                      id: "renewalType_Manual",
                      name: "renewalType",
                    },
                    domProps: {
                      value: _vm.$enums.SubscriptionRenewalType.MANUAL,
                      checked: _vm._q(
                        _vm.renewalType,
                        _vm.$enums.SubscriptionRenewalType.MANUAL
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.renewalType =
                          _vm.$enums.SubscriptionRenewalType.MANUAL
                      },
                    },
                  }),
                  _vm._v(" "),
                  _vm._m(1),
                ]),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _vm.isShowPrioritySupport
            ? _c("div", [
                _c("h4", [_vm._v("Priority Support")]),
                _vm._v(" "),
                _vm._m(2),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-4" }, [
                  _c("div", { staticClass: "col ms-md-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.prioritySupport,
                              expression: "prioritySupport",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "radio",
                            id: "prioritySupport_false",
                            name: "prioritySupport",
                          },
                          domProps: {
                            value: false,
                            checked: _vm._q(_vm.prioritySupport, false),
                          },
                          on: {
                            change: function ($event) {
                              _vm.prioritySupport = false
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "prioritySupport_false" },
                          },
                          [_vm._v("No thanks")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-check" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.prioritySupport,
                              expression: "prioritySupport",
                            },
                          ],
                          staticClass: "form-check-input",
                          attrs: {
                            type: "radio",
                            id: "prioritySupport_true",
                            name: "prioritySupport",
                          },
                          domProps: {
                            value: true,
                            checked: _vm._q(_vm.prioritySupport, true),
                          },
                          on: {
                            change: function ($event) {
                              _vm.prioritySupport = true
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            staticClass: "form-check-label",
                            attrs: { for: "prioritySupport_true" },
                          },
                          [
                            _vm._v(
                              "Yes, include Priority Support - " +
                                _vm._s(_vm.calcPrioritySupportPrice)
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-12 col-md-6 mt-2 ps-3" },
          [
            _c("transaction-preview"),
            _vm._v(" "),
            _c("div", { staticClass: "d-grid gap-2" }, [
              !_vm.isPaymentMethodEmpty &&
              _vm.isRenewal &&
              _vm.isRecurring &&
              !_vm.isExpired &&
              !_vm.isFreeTrial
                ? _c(
                    "button",
                    {
                      key: "buttonUpdateSubscriptionSettings",
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnUpdateSubscriptionSettings.apply(
                            null,
                            arguments
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tUpdate Subscription Settings\n\t\t\t\t\t"
                      ),
                    ]
                  )
                : _c(
                    "button",
                    {
                      key: "buttonNexyPaymentInfo",
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnGoToPayment.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tNext: Payment Information\n\t\t\t\t\t"
                      ),
                    ]
                  ),
              _vm._v(" "),
              !_vm.isExpired && !_vm.isFreeTrial
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnCancel.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("\n\t\t\t\t\t\tCancel changes\n\t\t\t\t\t")]
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fw-bold p-2" }, [
      _vm._v(
        "\n\t\t\t\tThis is testing environment, to make a purchase, please visit: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.perrlacomplete.com/Public/#/cart-step-1",
          },
        },
        [_vm._v("https://www.perrlacomplete.com/Public/#/cart-step-1")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "label",
      { staticClass: "form-check-label", attrs: { for: "renewalType_Manual" } },
      [
        _vm._v("Renew manually "),
        _c("span", { staticClass: "fw-300" }, [
          _vm._v("(your account exipres unless you renew it)"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "help-text ms-md-2" }, [
      _vm._v("\n\t\t\t\t\t\t- Expedited help from our Customer Support team."),
      _c("br"),
      _vm._v(
        "\n\t\t\t\t\t\t- Your request automatically moves to the top of our Support queue."
      ),
      _c("br"),
      _vm._v(
        "\n\t\t\t\t\t\t- The fastest way to get our help when you need it.\n\t\t\t\t\t"
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }