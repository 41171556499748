var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-5" },
    [
      !_vm.$isLive
        ? _c("div", { staticClass: "alert alert-danger" }, [_vm._m(0)])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "h1",
        { staticClass: "text-center mt-4 mb-0 text-large d-none d-md-block" },
        [_vm._v("\n\t\tSubscribe to PERRLA\n\t")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "boxset-wrp" },
        [
          _c(
            "transition",
            { attrs: { name: "route-fade", mode: "out-in", appear: "" } },
            [
              _vm.isSignIn
                ? _c("div", { key: "divSignIn", staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-divider-right pt-2" },
                      [
                        _c("h2", { staticClass: "text-center mb-3" }, [
                          _vm._v("\n\t\t\t\t\t\tSign in\n\t\t\t\t\t"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "form",
                          [
                            _vm.isLoginError
                              ? _c(
                                  "div",
                                  { staticClass: "alert alert-danger" },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\tThe email or password provided is incorrect\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c("form-input", {
                              attrs: {
                                label: "Email",
                                "form-id": _vm.zFormIdLogin,
                                "is-horizontal": true,
                                "validate-enum":
                                  _vm.$enums.Validation.EMAIL_REQUIRED,
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _vm._v(" "),
                            _c("form-password", {
                              attrs: {
                                label: "Password",
                                "form-id": _vm.zFormIdLogin,
                                "is-horizontal": true,
                                "validate-enum": _vm.$enums.Validation.REQUIRED,
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "p",
                              { staticClass: "link-forgot-password" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "hover-underline",
                                    attrs: {
                                      to: {
                                        name: "ForgotPassword",
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\tForgot password?\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-grid gap-2 mt-4" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success",
                                  attrs: { type: "submit" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.btnSignIn.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tSign in\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-primary",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.isSignIn = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\tCreate a new account\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 pt-2 org-size ps-3" },
                      [
                        _c("img", {
                          staticClass: "mx-auto d-none d-md-block",
                          attrs: {
                            src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/logo.png",
                            width: "140px",
                            role: "presentation",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "h1",
                          { staticClass: "d-none d-md-block text-center" },
                          [_vm._v("\n\t\t\t\t\t\tWelcome back!\n\t\t\t\t\t")]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          [
                            _c("strong", [
                              _vm._v("Having trouble accessing your account?"),
                            ]),
                            _c("br"),
                            _vm._v("\n\t\t\t\t\t\tYou can "),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "ForgotPassword",
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\treset your account password here\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(".\n\t\t\t\t\t"),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\tIf that doesn't work, reach out to our Support team. You can reach them via email at "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:support@perrla.com?subject=I%27ve%20got%20a%20question",
                                },
                              },
                              [_vm._v("support@perrla.com")]
                            ),
                            _vm._v(", or \n\t\t\t\t\t\t"),
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "ContactSupport",
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\tcontact them here\n\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _vm._v(".\n\t\t\t\t\t"),
                          ],
                          1
                        ),
                      ]
                    ),
                  ])
                : _c("div", { key: "divRegister", staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6 col-divider-right pt-2" },
                      [
                        _c(
                          "form",
                          [
                            _c("h2", { staticClass: "text-center mb-3" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tCreate your account\n\t\t\t\t\t\t"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("form-input", {
                              attrs: {
                                label: "First Name",
                                "form-id": _vm.zFormIdSubcribe,
                                "is-horizontal": true,
                                "validate-enum": _vm.$enums.Validation.REQUIRED,
                              },
                              model: {
                                value: _vm.firstName,
                                callback: function ($$v) {
                                  _vm.firstName = $$v
                                },
                                expression: "firstName",
                              },
                            }),
                            _vm._v(" "),
                            _c("form-input", {
                              attrs: {
                                label: "Last Name",
                                "form-id": _vm.zFormIdSubcribe,
                                "is-horizontal": true,
                                "validate-enum": _vm.$enums.Validation.REQUIRED,
                              },
                              model: {
                                value: _vm.lastName,
                                callback: function ($$v) {
                                  _vm.lastName = $$v
                                },
                                expression: "lastName",
                              },
                            }),
                            _vm._v(" "),
                            _c("form-input", {
                              attrs: {
                                label: "Email",
                                "form-id": _vm.zFormIdSubcribe,
                                "is-horizontal": true,
                                "validate-enum":
                                  _vm.$enums.Validation.EMAIL_REQUIRED,
                              },
                              model: {
                                value: _vm.email,
                                callback: function ($$v) {
                                  _vm.email = $$v
                                },
                                expression: "email",
                              },
                            }),
                            _vm._v(" "),
                            _c("form-password", {
                              attrs: {
                                label: "Password",
                                "form-id": _vm.zFormIdSubcribe,
                                "is-horizontal": true,
                                "validate-enum": _vm.$enums.Validation.REQUIRED,
                              },
                              model: {
                                value: _vm.password,
                                callback: function ($$v) {
                                  _vm.password = $$v
                                },
                                expression: "password",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "d-grid gap-2 mt-4 mb-3" },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success",
                                    attrs: { type: "submit" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.btnCreateAccount.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\tCreate my account\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-outline-primary",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.isSignIn = true
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\tSign in to an existing account\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("p", { staticClass: "org-size" }, [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tWant to try PERRLA before you buy?\n\t\t\t\t\t\t\t"
                              ),
                              _c(
                                "a",
                                {
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.btnGoToFreeTrial.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "Click here to start a 7-day Free Trial."
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "col pt-2 org-size ps-3" }, [
                      _c("p", [
                        _c("strong", [
                          _vm._v("What's included in a PERRLA subscription?"),
                        ]),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\tEvery PERRLA Subscription includes access to:"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\t- PERRLA's Microsoft Word Add-in"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\t- Web-based PERRLA Online Application"
                        ),
                        _c("br"),
                        _vm._v("\n\t\t\t\t\t\t- Google Chrome Extension"),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\t- Access to our amazing Customer Support Team"
                        ),
                        _c("br"),
                        _vm._v(
                          "\n\t\t\t\t\t\t- Immediate access to all new features & improvements\n\t\t\t\t\t"
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c("strong", [
                            _vm._v(
                              "I've used PERRLA before. Should I create a new account?"
                            ),
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n\t\t\t\t\t\tPlease don't. Your existing PERRLA account has all of your papers, references, and work saved in it. We recommend you \n\n\t\t\t\t\t\t"
                          ),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "Login",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tlog into your existing account\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(
                            "\n\t\t\t\t\t\tand renew your subscription from there. Plus, it could save you some money!\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        [
                          _c("strong", [
                            _vm._v(
                              "What if I have trouble getting started with PERRLA?"
                            ),
                          ]),
                          _c("br"),
                          _vm._v(
                            "\n\t\t\t\t\t\tWe have an amazing US-based Support team standing by. They work every day until 10pm Central ready to help. You can reach them via email at "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "mailto:support@perrla.com?subject=I%27ve%20got%20a%20question",
                              },
                            },
                            [_vm._v("support@perrla.com")]
                          ),
                          _vm._v(", or \n\n\t\t\t\t\t\t"),
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "ContactSupport",
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\tcontact them here\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _vm._v(".\n\t\t\t\t\n\t\t\t\t\t"),
                        ],
                        1
                      ),
                    ]),
                  ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("price-grid-v2", {
        attrs: { "is-show-prices": true, "is-show-testimonial-video": false },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "divider" }),
      _vm._v(" "),
      _vm._m(1),
      _vm._v(" "),
      _c("div", { staticClass: "divider" }),
      _vm._v(" "),
      _vm._m(2),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "fw-bold p-2" }, [
      _vm._v(
        "\n\t\t\tThis is testing environment, to make a purchase, please visit: "
      ),
      _c(
        "a",
        {
          attrs: {
            href: "https://www.perrlacomplete.com/Public/#/cart-step-1",
          },
        },
        [_vm._v("https://www.perrlacomplete.com/Public/#/cart-step-1")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("main", { staticClass: "org-size" }, [
        _c("h2", { staticClass: "text-center" }, [
          _vm._v("\n\t\t\t\tPERRLA handles all the formatting.\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("img", {
          staticClass: "img-fluid mb-2",
          attrs: {
            src: "https://perrla.s3.amazonaws.com/perrla_assets_2022.11.18/handle-formatting.png",
            role: "presentation",
          },
        }),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "PERRLA formats your entire document for you, automatically – including "
          ),
          _c("strong", [_vm._v("page margins")]),
          _vm._v(", "),
          _c("strong", [_vm._v("text styles")]),
          _vm._v(", "),
          _c("strong", [_vm._v("paragraph spacing")]),
          _vm._v(", "),
          _c("strong", [_vm._v("headers")]),
          _vm._v(", and "),
          _c("strong", [_vm._v("section breaks")]),
          _vm._v("."),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v("Create a paper for all your assignments: "),
          _c("strong", [_vm._v("Research Papers")]),
          _vm._v(", "),
          _c("strong", [_vm._v("Discussion Posts")]),
          _vm._v(", "),
          _c("strong", [_vm._v("Reference Lists")]),
          _vm._v(", and "),
          _c("strong", [_vm._v("Annotated Bibliographies ")]),
          _vm._v("for the APA 7 and MLA 9 formats."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("section", { staticClass: "section-max-width mx-auto" }, [
      _c("main", { staticClass: "org-size" }, [
        _c("h2", { staticClass: "text-center mb-3" }, [
          _vm._v("\n\t\t\t\tSome of our most popular features:\n\t\t\t"),
        ]),
        _vm._v(" "),
        _c("ul", [
          _c("li", [
            _c("strong", [
              _vm._v(
                "Automatically retrieve reference data for journal articles, books, and websites"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Google Chrome Web")]),
            _vm._v(" Extension lets you create references from any website"),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [_vm._v("Reference Wizard")]),
            _vm._v(
              " guides you step-by-step to create all other reference types"
            ),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v("Create perfect citations for every type of reference"),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Automatically generates & alphabetizes your references section"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Save your references for use in future papers in the Reference Library"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Create research papers, discussion posts, reference lists, and annotated bibliographies"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "Insert tables, figures, abstracts, tables of content, section headings, & appendices"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("li", [
            _c("strong", [
              _vm._v(
                "US-based Customer Support available 7 days a week until 10pm Central"
              ),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }