var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav-tab-content", [
    _c("h2", [_vm._v("Download Paper")]),
    _vm._v(" "),
    _c(
      "table",
      { staticClass: "table table-sm table-borderless mb-0 align-middle" },
      [
        _c("tbody", [
          _vm.$store.state.paperEdit.meta.DocumentType ===
          _vm.$enums.DocumentType.DISCUSSION_POST
            ? _c("tr", [
                _c("td", { staticClass: "ps-0", attrs: { width: "350px" } }, [
                  _c("div", { staticClass: "d-grid" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.copyDiscussionPostToClipboard.apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t\tCopy paper to clipboard\n\t\t\t\t\t\t"
                        ),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\tCopy your paper's content to the clipboard so it's easy to paste into an online forum.\n\t\t\t\t\t"
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "ps-0", attrs: { width: "350px" } }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnDownloadedDocument(
                          _vm.$enums.DownloadPaperContentType.WORD_SIMPLE
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tDownload Simplified Word File (.docx)\n\t\t\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tGreat option for turning in a paper to your teacher!\n\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { staticClass: "ps-0" }, [
              _c("div", { staticClass: "d-grid" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.btnDownloadedDocument(
                          _vm.$enums.DownloadPaperContentType.PDF
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t\t\tDownload as PDF (.pdf)\n\t\t\t\t\t\t"
                    ),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tPDF's are great for static files, sharing, & printing.\n\t\t\t\t\t"
                ),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("br"),
    _c("br"),
    _c("br"),
    _vm._v(" "),
    _c("h2", [_vm._v("Print Paper")]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-primary",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.btnPrintPdf.apply(null, arguments)
          },
        },
      },
      [_vm._v("\n\t\tPrint my paper\n\t")]
    ),
    _vm._v(" "),
    _c("br"),
    _c("br"),
    _c("br"),
    _vm._v(" "),
    _c(
      "form",
      [
        _c("h2", [_vm._v("Email Paper")]),
        _vm._v(" "),
        _c("form-input", {
          attrs: {
            label: "File Name",
            "help-text":
              "You can give your paper a custom name, different from the paper's title.",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.REQUIRED,
          },
          model: {
            value: _vm.fileName,
            callback: function ($$v) {
              _vm.fileName = $$v
            },
            expression: "fileName",
          },
        }),
        _vm._v(" "),
        _c("form-input", {
          attrs: {
            label: "Email",
            "help-text": "Enter the email address we should send the paper to.",
            "form-id": _vm.zFormId,
            "validate-enum": _vm.$enums.Validation.EMAIL_REQUIRED,
          },
          model: {
            value: _vm.emailAddress,
            callback: function ($$v) {
              _vm.emailAddress = $$v
            },
            expression: "emailAddress",
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "form-group mb-0 py-2" }, [
          _c("label", [_vm._v("Select a format")]),
          _vm._v(" "),
          _c("div", { staticClass: "help-text mb-1" }, [
            _vm._v(
              "\n\t\t\t\tChoose the format for your attached paper.\t\n\t\t\t"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.emailExportType,
                      expression: "emailExportType",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "radio", id: "Export_Radio_SimpleWord" },
                  domProps: {
                    value: 2,
                    checked: _vm._q(_vm.emailExportType, 2),
                  },
                  on: {
                    change: function ($event) {
                      _vm.emailExportType = 2
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "Export_Radio_SimpleWord" },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\tWord file (.docx)\n\t\t\t\t\t\t")]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.emailExportType,
                      expression: "emailExportType",
                    },
                  ],
                  staticClass: "form-check-input",
                  attrs: { type: "radio", id: "Export_Radio_Pdf" },
                  domProps: {
                    value: 1,
                    checked: _vm._q(_vm.emailExportType, 1),
                  },
                  on: {
                    change: function ($event) {
                      _vm.emailExportType = 1
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "Export_Radio_Pdf" },
                  },
                  [_vm._v("\n\t\t\t\t\t\t\tPDF (.pdf)\n\t\t\t\t\t\t")]
                ),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { type: "submit" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.sendEmailAsAttachment.apply(null, arguments)
              },
            },
          },
          [_vm._v("\n\t\t\tEmail as attachment\n\t\t")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }