import { render, staticRenderFns } from "./TabOutline.vue?vue&type=template&id=f6a79662&scoped=true"
import script from "./TabOutline.vue?vue&type=script&lang=js"
export * from "./TabOutline.vue?vue&type=script&lang=js"
import style0 from "./TabOutline.vue?vue&type=style&index=0&id=f6a79662&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f6a79662",
  null
  
)

export default component.exports