var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "generic-modal-wrap",
    {
      attrs: {
        "modal-id": "SharedReferencesReceived",
        "is-close-button": false,
      },
    },
    [
      _c("h2", { staticClass: "nodal-title" }, [
        _vm._v("\n\t\tYou've got shared references!\n\t"),
      ]),
      _vm._v(" "),
      _vm.thisSharedReference
        ? _c("div", { key: "divSharedReferenceInfo" }, [
            _c("p", [
              _c("strong", [
                _vm._v(
                  "It looks like " +
                    _vm._s(_vm.thisSharedReference.firstName) +
                    " has shared " +
                    _vm._s(_vm.thisSharedReference.references.length) +
                    " of their references with you."
                ),
              ]),
              _vm._v(
                "\n\t\t\t\tIf you add the references to your PERRLA account, we'll create a copy in your library so you to use them in your own papers."
              ),
            ]),
            _vm._v(" "),
            _vm.thisSharedReference.message.length > 0
              ? _c("p", [
                  _vm._v(
                    _vm._s(_vm.thisSharedReference.firstName) +
                      " sent a message along with the references:"
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.thisSharedReference.message.length > 0
              ? _c("div", { staticClass: "message-wrp word-break" }, [
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t" +
                        _vm._s(_vm.thisSharedReference.message) +
                        "\n\t\t\t"
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h2", { staticClass: "mb-3" }, [
              _vm._v(
                "Do you want to copy these reference to your Reference Library?"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-danger",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnIgnore.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tDecline Shared References\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col" }, [
                _c("div", { staticClass: "d-grid" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.btnAccept.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t\tAdd References to My Library\n\t\t\t\t\t"
                      ),
                    ]
                  ),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }